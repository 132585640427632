
	.calculating-texts {
		font-size:24px;
		line-height:28px;
		text-align:center;
		margin:50px 0;
	}
	#content {
		width:100%;
		height:20px;
		margin:50px auto;
		background:#ededed;
		-webkit-box-sizing:border-box;
		-moz-box-sizing:border-box;
		box-sizing:border-box;
		position:relative;
	}
	.expand {
		width:0;
		height:20px;
		margin:0;
		background:#5c5c61;
		position:absolute;
		top:0;
		left:0;
		-moz-animation:fullexpand 2s ease-out forwards;
		-webkit-animation:fullexpand 2s ease-out forwards;
		animation:fullexpand 2s ease-out forwards;
		-webkit-box-sizing:border-box;
		-moz-box-sizing:border-box;
		box-sizing:border-box;
	}
	#linearGradient-2 stop.color-1 {
		stop-color:#00ffc8;
	}
	/* Full Width Animation Bar */
	@keyframes fullexpand {
		0% {
			width:0;
		}
		100% {
			width:100%;
		}
	}