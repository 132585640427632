.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
@-webkit-keyframes pulse{from,to{-webkit-transform:scale3d(1,1,1);transform:scale3d(1,1,1)}50%{-webkit-transform:scale3d(1.05,1.05,1.05);transform:scale3d(1.05,1.05,1.05)}}@keyframes pulse{from,to{-webkit-transform:scale3d(1,1,1);transform:scale3d(1,1,1)}50%{-webkit-transform:scale3d(1.05,1.05,1.05);transform:scale3d(1.05,1.05,1.05)}}
.pulse{
  -webkit-animation-name:pulse;
  animation-name:pulse;

  &.animated {
    animation-iteration-count: 10;
  }
}

.fade-appear,
.fade-enter {
  opacity: 0;
  z-index: 1;
}

.fade-appear-active,
.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 100ms linear 50ms;
}

.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0;
  transition: opacity 50ms linear;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

/* slide enter */
.slide-enter {
  opacity: 0;
  transform: scale(0.97) translateY(5px);
  z-index: 1;
}
.slide-enter.slide-enter-factive {
  opacity: 1;
  transform: scale(1) translateY(0);
  transition: opacity 3000ms linear 1000ms, transform 3000ms ease-in-out 1000ms;
}

/* slide exit */
.slide-exit {
  opacity: 1;
  transform: scale(1) translateY(0);

}
.slide-exit.slide-exit-active {
  opacity: 0;
  transform: scale(0.97) translateY(5px);
  transition: opacity 1500ms linear, transform 1500ms ease-out;
}
.slide-exit-done {
  opacity: 0;
}

/* dialog */
.dialog-enter {
  opacity: 0.01;
  transform: scale(1.1);
}

.dialog-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: all 300ms;
}

.dialog-exit {
  opacity: 1;
  transform: scale(1);
}

.dialog-exit-active {
  opacity: 0.01;
  transform: scale(1.1);
  transition: all 300ms;
}

/* alert */
.alert-enter {
  opacity: 0;
  transform: scale(0.9);
}
.alert-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}
.alert-exit {
  opacity: 1;
}
.alert-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}