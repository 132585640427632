// -----------------------------------------------------------------------------
// This file contains very basic styles.
// -----------------------------------------------------------------------------
html {
	position: relative;
	min-height: 100%;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-rendering: optimizeLegibility;
}

.cursor-not-allowed {
	cursor: not-allowed;
}

.form-control {
	font-size: 16px;
	background-color: $form-control-bgcolor;
	padding: 18px 15px;
	display: block;
	border-width: 0;
	width: 100%;
	height: auto;
	margin: 0 auto 16px;
	text-align: center;
	border-radius: 8px;
	-webkit-appearance: none;
}

input:focus {
	outline:none;
}
.form-control#card-name {
	border: 1px solid $form-control-cardname-bordercolor;
}
.form-control:focus, input:focus {
	outline: none;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
::-webkit-input-placeholder {
	color:$input-placeholder-color !important;
}
::-moz-placeholder {
	color:$input-placeholder-color !important;
}
:-ms-input-placeholder {
	color:$input-placeholder-color !important;
}
:-moz-placeholder {
	color:$input-placeholder-color !important;
}
.form-group label {
	font-size: 1em;
	font-weight: 400;
}
.form-left-align .form-control {
	text-align: left;
}
.input-group-text {
	background-color: $input-group-text-bgcolor;
	text-align: center;
	min-width: 40px;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	border-radius: 8px !important;
	color: $input-group-text-color;
	border: 0px solid transparent;
}
.input-group {
	border-radius: 8px !important;
	border: 1px solid $input-group-bordercolor;
}
.input-group-prepend {
	margin-right: 0;
}

.mobile-device {
	position: relative;
	width: 215px;
	height: 425px;
	margin: 0 auto 25px;

	&::before, &::after {
		content: '';
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		z-index: -1;
	}

	.mobile-frame  {
		width: 215px;
		height: 425px;
		z-index: 2;

		background-size: 215px 425px;
		background-repeat: no-repeat;
		background-image: url(../images/mobile-phone.jpg);
		-webkit-mask-repeat: no-repeat;
		mask-repeat: no-repeat;
		-webkit-mask-image: url(../images/mobile-bezel.svg);
		mask-image: url(../images/mobile-bezel.svg);
	}

	&.menu .mobile-screen {
		background-image: url(../images/menu-glass.jpg);
	}
	&.coach .mobile-screen {
		background-image: url(../images/coach-glass.jpg);
	}
	&.recipe .mobile-screen {
		background-image: url(../images/recipe-glass.jpg);
	}
	&.quiz .mobile-screen {
		background-image: url(../images/quiz-glass.jpg);
	}
	&.grocery .mobile-screen {
		background-image: url(../images/grocery-glass.jpg);
	}

	.mobile-screen {
		width: 191px;
		height: 404px;
		background-size: 191px 404px;
		background-repeat: no-repeat;
		margin-left: 12px;
		margin-top: 10px;
	}

	.mobile-frame, .mobile-screen {
		position: absolute;
		top: 0;
		left: 0;
	}
}

.modal.show .modal-dialog {
	-webkit-transform: translate(0,0);
	transform: translate(0,0);
}