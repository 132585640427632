/*----------------------------------------
| Take Steps
----------------------------------------*/
.take-steps {
	position: relative;
	overflow: hidden;
	padding: 60px 0;
	background: #504db4;
	color: #FFF;
}
.take-steps a  {
	color: #FFF;
	text-decoration: none;
}

/*----------------------------------------
| Social Media
----------------------------------------*/
.social-media {
	list-style: none;
	padding: 0;
	text-align: center;
}
.social-media li {
	display: inline-block;
	padding: 10px 26px;
}

.social-media a,
.social-media a:hover {
	color: #FFF;
	text-decoration: none;
}
.social-media li a i {
	position: relative;
	display: inline-block;
	width: 100px;
	height: 100px;
	line-height: 100px;
	font-size: 50px;
	color: #fff;
	margin: 0 auto 10px auto;
	z-index: 2;
}
.social-media li a i:after {
	content: '';
	background-color: #fff;
	width: 100px;
	position: absolute;
	height: 100px;
	top: 0;
	left: 0;
	opacity: .2;
	border-radius: 100px;
	z-index: -1;
	transition: opacity .3s, background-color .3s;
	-moz-transition: opacity .3s, background-color .3s;
	-webkit-transition: opacity .3s, background-color .3s;
}
.social-media li a i:after,
.social-media li a i:hover {
	background-color: #160e77;
	border-radius: 100px;
}