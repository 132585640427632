  :root {
    --input-padding-x: 17px;
    --input-padding-y: 17px;
  }
  .form-label-group {
    position: relative;
    margin-bottom: 1rem;
  }
  .form-label-group input,
  .form-label-group label {
    padding: var(--input-padding-y) var(--input-padding-x);
  }
  .form-label-group label {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    margin-bottom: 0; /* Override default `<label>` margin */
    font-size: 16px;
    line-height: 1.5;
    color: #848e98;
    cursor: text; /* Match the input under the label */
    border: 1px solid transparent;
    border-radius: .25rem;
    transition: all .1s ease-in-out;
    text-align: center;
    z-index: 5;
  }
  .form-label-group input::-webkit-input-placeholder {
    color: transparent !important;
  }
  .form-label-group input:-ms-input-placeholder {
    color: transparent !important;
  }
  .form-label-group input::-ms-input-placeholder {
    color: transparent !important;
  }
  .form-label-group input::-moz-placeholder {
    color: transparent !important;
  }
  .form-label-group input::placeholder {
    color: transparent !important;
  }
  .form-label-group input:not(:placeholder-shown) {
    padding-top: calc(var(--input-padding-y) + var(--input-padding-y) * (1 / 2));
    padding-bottom: calc(var(--input-padding-y) / 2);
  }
  .form-label-group input:not(:placeholder-shown) ~ label {
    padding-top: calc(var(--input-padding-y) / 2);
    padding-bottom: calc(var(--input-padding-y) / 2);
    font-size: 12px;
    color: #000;
    font-weight: 700;
  }

  /* Fallback for Edge
  -------------------------------------------------- */
  @supports (-ms-ime-align: auto) {
    .form-label-group label {
      display: none;
    }
    .form-label-group input::-ms-input-placeholder {
      color: #777 !important;
    }
  }

  /* Fallback for IE
  -------------------------------------------------- */
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .form-label-group label {
      display: none;
    }
    .form-label-group input:-ms-input-placeholder {
      color: #777 !important;
    }
  }