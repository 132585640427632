body {
	min-height: 100%;
	font-family: $font-main;
	font-size: 1rem;
}

h1, h2, h3, h4, h5, h6, .heading {
	font-family: $font-main;
	letter-spacing: -.03rem;
	font-weight: 300;

	span {
		// color: $subtext-color;
		// font-family: $font-secondary;
		// font-weight: 800;
	}
}

b,
p b,
strong,
p strong {
	font-weight: bold !important;
}

h3 {
	font-family: inherit;
	font-size: 24px;
	display: block;
	margin-bottom: 20px;

	span {
		color: $green;
	}
}

p {
	font-family: $font-main;
	font-size: 1rem;
	font-weight: 300;
	line-height: 1.8rem;
}

.font-size-13 {
	font-size: 13px;
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
	body {
		font-size: 18px;
	}
}