/*----------------------------------------
| Modal Iframe
----------------------------------------*/
#loadIframeModal .modal-body {
	padding: 0 0 0 0;
	border-radius: 3px 3px 0 0;
}
#loadIframeModal .modal-iframe {
	border: none;
	height: 100%;
	width: 100%;
	border-radius: 3px 3px 0 0;
}
.scroll-wrapper {
	background-color: #fff;
	position: fixed; 
	right: 0; 
	bottom: 0; 
	left: 0;
	top: 0;
}
.scroll-wrapper iframe {
	height: 100%;
	width: 100%;
}
#loadIframeModal .close {
	position: fixed;
	top: 10px;
	right: 24px;
	z-index: 500;
	background: #f5f5f5;
	border-radius: 50%;
	width: 32px;
	height: 32px;
	opacity: 1;
}