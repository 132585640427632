.quiz-page {
	/*----------------------------------------
	| Banner
	----------------------------------------*/
	.banner {
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
		border-radius: 0;
		text-align: center;
		position: relative;
		padding-top: 70px;
	}
	.banner h3 {
		font-family: $font-main;
		color: inherit;
		font-size: 20px;
		line-height: 32px;
		margin-bottom: 20px;
		font-weight: 500;
	}
	.banner-sub-text {
		font-size: 1em!important;
	}
	.banner h3 span {
		font-size: 42px;
	}
	.banner.inner {
		padding: 5px 0;
		background: #FFF;
		min-height: 60px;
		margin-top: 0;
		height: auto;
	}
	.banner.inner.results {
		background: #2d3036;
			margin-bottom: 10px;
	}
	.banner.inner.dark {
		border-bottom: 1px solid #eeeeee;
			box-shadow: 0px 8px 29px -8px #ececec;
	}
	.banner.inner.checkout {
		background: #2b3035;
		color: #FFF;
	}
	.banner.checkout h3 {
		font-size: 1.4rem;
		color: #FFF;
	}
	.banner.checkout p {
		font-size: 1em;
		color: inherit;
	}
	.banner.checkout p span {
		font-weight: 900;
	}
	.banner.checkout h3 span {
		font-size: 1.4rem;
		color: #00ffc4 !important;
	}
	.banner.inner.results svg g #MACROFARE-Color, .banner.inner.checkout svg g #MACROFARE-Color  {
		fill: #FFF;
	}
	.banner.inner h1 {
		font-size: 1.8rem;
		line-height: 1.8rem;
		text-transform: uppercase;
		font-weight: 700;
	}
	.banner.final {
		background-blend-mode: hard-light;
		background: #FFF;
		background-position: center 60%;
		background-size: cover;
		margin-bottom: 0;
		min-height: 60vh;
		color: #2f2f2f;
	}

	/*----------------------------------------
	| Intro
	----------------------------------------*/
	.intro {
		background: #34363a;
		color: #fff;
		text-align: center;
		position: relative;
		padding-bottom: 120px !important;
		padding-top: 40px;
		background-image: url(../images/food-cover-mobile.jpg);
		background-size: cover;
		background-position: center;
		background-blend-mode: multiply;
	}
	.intro h1, .intro h2 {
		font-size: 1.6rem;
		font-family: 'Raleway', sans-serif;
		}
	.intro h1.font-weight-bold {
		font-weight: 800 !important;
	}
	.intro h2 {
		font-size: 1rem;
		}
	.intro h5 {
		font-size: 1.1rem;
	}
	@media (min-width: 325px) {
		.intro h2 {
		font-size: 1.2em;
		}
		.intro h1 {
			font-size: 2.0rem;
		}
		.intro h5 {
		font-size: 1.3rem;
		}
	}
	@media (min-width: 768px) {
		.intro h2 {
		font-size: 2.2rem;
		}
		.intro h1 {
			font-size: 3.8rem;
		}
		.intro h5 {
		font-size: 1.6rem;
		}
	}
	@media (min-width: 1200px) {
		.intro {
			text-align: left;
		}
		.intro h2 {
		font-size: 2.2rem;
		}
		.intro h1 {
			font-size: 6.8rem;
			line-height: 6.8rem;
		}
	}

	.vertical-center {
		min-height: 100%;
		min-height: calc(90vh + 50px);
		display: flex;
		align-items: center;
	}
	@media (min-width: 325px) {
		.vertical-center {
				min-height: calc(90vh - 20px);
		}
	}

	.how-it-works {
		position: relative;
		background: #FFF;
		text-align: center;
		padding: 60px 0 120px;
		z-index: 0;
		overflow: hidden;
	}
	.how-it-works .container {
		padding: 60px 30px;
		background: #FFF;
		border: 1px solid #eaeaea;
		border-radius: 40px;
		box-shadow: 0px 4px 70px -33px #a9a9ac;
		width: 95%;
		max-width: 968px;
	}
	.how-it-works h3 {
		font-size: 1.3rem;
			line-height: 2.2rem;
	}
	.how-it-works svg {
		width: 100%;
		max-width: 135px;
		height: auto;
	}
	h1.home-title {
		font-size: 42px;
	}
	.guarantee h2.title {
		font-size: 26px;
	}

	/*--------------------------------------------------------------
	| Gender Selection
	--------------------------------------------------------------*/
	.select-gender .gender {
		display: block;
		text-decoration: none;
		max-width: 425px;
		overflow: hidden;
		margin: 10px auto;
		background: #0282c9;
		box-shadow: 1px 2px 8px -4px #303033;
		width: 100%;
		border: none;
		cursor: pointer;
		outline: none;
	}
	.select-gender .gender:hover {
		background-color: #035482;
	}
	.select-gender .take-quiz {
		background: #ff602d;
	}
	.select-gender .gender.female {
		background: #f60561 !important;
	}
	.select-gender .gender.female:hover {
		background: #b70247 !important;
	}
	.select-gender .icon,
	.select-gender .text {
		display: block;
	}
	.select-gender .male .icon, .select-gender .female .icon {
		background-color: rgba(0, 219, 171, .8);
	}
	.select-gender .icon {
		color: #fff;
		fill: #fff;
		width: 90%;
		background: url(../images/circle-bg.svg);
		max-width: 260px;
		background-repeat: no-repeat;
		background-size: contain;
		background-position: center;
		height: 185px;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		margin: 0 auto;
	}
	.select-gender .icon svg {
		height: 50%;
		align-self: center;
	}
	.select-gender .text {
		font-family: 'Raleway', sans-serif;
		color: #FFF !important;
		padding: 20px 0;
		font-weight: 800;
		font-size: 1.1rem;
		width: 100%;
		margin: 0 auto;
		position: relative;
		text-align: center;
		text-transform: uppercase;
	}
	.select-gender .male .text:after,
	.select-gender .female .text:after {
		content: "\f222";
		font-family: 'Font Awesome 5 Free';
		position: absolute;
		right: 5%;
	}
	.select-gender .female .text:after {
		content: "\f221";
	}
	.select-gender .male .text, .select-gender .female .text {
		color: #FFF;
	}

	/*--------------------------------------------------------------
	| Step Container
	--------------------------------------------------------------*/
	.step-container {
		padding-bottom: 20px;
	}
	.step-container.shipping {
		position: relative;
		padding: 60px 0 34.6vh;
	}
	.step-container h2 {
		font-size: 1.2rem;
		font-weight: 800;
		max-width: 768px;
		margin: 0 auto;
	}
	.step-container.special h2 {
		font-size: 2rem;
	}
	.step-container.payment {
		position: relative;
		padding: 60px 0;
		background: #FFF;
	}
	.step-container.payment .col-12 {
		box-shadow: 0px 12px 90px -30px #d9d9d9;
			background: #FFF;
	}
	.step-container.payment-card {
		position: relative;
		padding: 20px 0 60px;
		background: #FFF;
	}
	.modal h3,
	.step-container h3 {
			font-size: 1.1rem;
			font-weight: 300 !important;
			max-width: 768px;
			margin: 10px auto 0;
	}
	.step-container.shipping h3 {
		font-size: 1.4rem;
	}
	.step-container h4 {
		text-align: center;
		font-weight: 100;
		font-size: 18px;
		line-height: 22px;
	}

	.ingredient-key {
		font-size: 1.1em;
		color: #626262;
	}
	.ingredient-key span {
		color: #4ed7b5;
			font-weight: 900;
			background: #4ed7b5;
			border-radius: 4px;
	}
	.download_pdf {
		border-radius: 8px;
	}
	h2.credit-title {
		font-size: 1.2rem !important;
		font-weight: 300;
		text-align: center;
		padding: 15px 0;
		border-top: 1px solid #dee2e6;
		max-width: 272px;
		border-right: 1px solid #dee2e6;
		border-left: 1px solid #dee2e6;
		border-radius: 8px 8px 0px 0px;
		margin: 0;
		margin-bottom: 0px !important;
		border-bottom: 1px solid #FFFF;
		position: relative;
		top: 1px;
		color: #000000;
	}

	/*--------------------------------------------------------------
	| Order Benefits
	--------------------------------------------------------------*/
	.order-benefits {
		color: #2d3036;
		font-size: 15px;
	}
	.order-benefits span {
		border: 1px solid #efefef;
		border-radius: 8px;
		padding-left: 0;
		background: #fafafa;
		display: block;
	}
	.order-benefits i {
		color: #000000;
		margin-right: 2px;
		background: #dcfff1;
		padding: 9px;
		border-top-left-radius: 8px;
		border-bottom-left-radius: 8px;
		border-right: 4px solid #009740;
		width: 36px;
    text-align: center;
	}
	.order-benefits .sub span {
		border: 1px solid #efefef;
		border-radius: 8px;
		padding-left: 0;
		background: #e3fff2;
		display: block;
		font-weight: 600;
	}
	.order-benefits .sub.last span {
		border: 1px solid #efefef;
		border-radius: 8px;
		padding-left: 0;
		background: #00c76a;
		display: block;
		font-weight: 800;
		color: #FFF;
		text-transform: uppercase;
	}
	.order-benefits.billing-terms span {
		font-weight: 600;
		border-color: #FFF;
		background: #FFF;
		border-bottom: 1px solid #0091ff;
		border-radius: 0px;
	}
	.order-benefits .sub i {
		color: #ffffff;
		margin-right: 2px;
		background: #07c373;
		padding: 9px;
		border-top-left-radius: 8px;
		border-bottom-left-radius: 8px;
		border-right: 4px solid #05944a;
	}
	.order-benefits.billing-terms i {
		color: #ffffff;
		margin-right: 5px;
		background: #008ff8;
		padding: 9px;
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
		border-right: none;
	}
	.macro_block {
		background: #073271;
		color: #FFF;
	}
	canvas {
		transform: scale(1.1, 1.1);
	}
	.result-container {
		max-height:300px;
		border-color:white;
		overflow: hidden;
		padding-top:20px;
		margin: 0 auto;
	}
	.result-title {
	 margin: 0 auto;
	 background: #00cfa2;
	 height: 40px;
	 box-shadow: 0px 17px 15px -15px rgba(18, 18, 18, 0.6);
	 max-width: 568px;
	}
	.result-title h3 {
		font-size: 1.2rem;
		font-weight: 800;
		color: #FFF;
		text-transform: uppercase;
	}

	/*--------------------------------------------------------------
	| plus / minus icon
	--------------------------------------------------------------*/
	.tcon {
		-webkit-appearance:none;
		-moz-appearance:none;
		appearance:none;
		border:none;
		cursor:pointer;
		display:-webkit-box;
		display:-ms-flexbox;
		display:flex;
		-webkit-box-pack:center;
		-ms-flex-pack:center;
		justify-content:center;
		-webkit-box-align:center;
		-ms-flex-align:center;
		align-items:center;
		height:40px;
		-webkit-transition:.3s;
		transition:.3s;
		-webkit-user-select:none;
		-moz-user-select:none;
		-ms-user-select:none;
		user-select:none;
		width:40px;
		background:transparent;
		outline:none;
		-webkit-tap-highlight-color:transparent;
		-webkit-tap-highlight-color:transparent;
	}
	.tcon > * {
		display:block;
	}
	.tcon:hover,.tcon:focus {
		outline:none;
	}
	.tcon::-moz-focus-inner {
		border:0;
	}
	.tcon-plus {
		position:relative;
		-webkit-transform:scale(0.75);
		transform:scale(0.75);
		width:100%;
	}
	.tcon {
		position:absolute;
		top:0;
		right:0;
		width:100%;
		margin: 0 auto;
	}
	.tcon-plus::before,.tcon-plus::after {
		content:"";
		display:block;
		width:50%;
		height:50%;
		position:absolute;
		top:100%;
		background: #fbfbfb;
		-webkit-transition:.3s;
		transition:.3s;
	}
	.tcon-plus:after {
		-webkit-transform:rotate(90deg);
		transform:rotate(90deg);
	}
	/* hover to change icon*/
	.checkbox-option input:checked~label .tcon-plus--minus::before {
		-webkit-transform:rotate(180deg) translate(0,0);
		transform:rotate(180deg) translate(0,0);
		width:50%;
	}
	.checkbox-option input:checked~label .tcon-plus--minus::after {
		-webkit-transform:rotate(-180deg) translate(0,0);
		transform:rotate(-180deg) translate(0,0);
		width: 100%;
			background: #00ffc8;
	}
	/* inclusion-status */
	.checkbox-option input~label .inclusion-status,
	.checkbox-option .inclusion-status {
		text-transform: lowercase;
		font-family: 'Raleway', sans-serif;
		font-size: 15px;
		line-height: 16px;
		width: 10%;
		color: #fff;
		text-align: center;
		font-weight: 600;
		position: absolute;
		right: 25px;
	}
	.checkbox-option input~label .inclusion-status::before,
	.checkbox-option .inclusion-status::before {
		content: '\f204';
		/*font-family: 'Font Awesome 5 Free';*/
		font-family: 'Font Awesome 5 Free';
		display: inline-block;
		font-size: 1.4rem;
		line-height: 1.4rem;
		color: #dcdcdc;
		margin-right: 5px;
	}
	.checkbox-option input:checked~label .inclusion-status::before,
	.checkbox-option.checked .inclusion-status::before {
		content: '\f205';
		color: #018df0;
	}

	/* single_selection_btn */
	.single_selection_btn {
		position: relative;
		font-size: 1.2rem;
		font-weight: 300;
		text-align: left;
		justify-content: start;
		height: 68px;
		padding-left: 1rem;
		padding-right: 3rem;

		&.btn-type-multi {
			height: 60px;
			margin-bottom: 15px;
			box-shadow: 0px 3px 8px -5px #5f5f66;

			&::after {
				font-size: 1.4rem;
    		line-height: 1.4rem;
			}
		}
	}
	.single_selection_btn:focus {
		box-shadow: 1px 2px 8px -4px #303033;
	}
	.single_selection_btn:hover {
		color: #000;
	}
	.single_selection_btn::after {
		content: '\f204';
		font-family: 'Font Awesome 5 Free';
		display: inline-block;
		color: #dcdcdc;
		text-transform: lowercase;
		font-weight: 600;
		position: absolute;
		right: 25px;
	}
	.single_selection_btn.selected::after {
		content: '\f205';
		color: #018df0;
	}
	.single_selection_btn.selected {
		background-color: #032c4a;
		color: #fff!important;
		text-decoration: none;
	}

	.tcon-visuallyhidden {
		border:0;
		clip:rect(0 0 0 0);
		height:1px;
		margin:-1px;
		overflow:hidden;
		padding:0;
		position:absolute;
		width:1px;
	}
	.tcon-visuallyhidden:active,.tcon-visuallyhidden:focus {
		clip:auto;
		height:auto;
		margin:0;
		overflow:visible;
		position:static;
		width:auto;
	}
	.button-wrap {
		padding-top: 20px;
		text-align: center;
	}
	.button-wrap .btn,
	.button-wrap .button-styled {
		padding: 25px 0;
		font-size: 1.4rem;
		margin: 0 auto;
		border-width: 3px;
		text-transform: capitalize;
		color: #000000;
		text-decoration: none;
		outline: none;
		font-weight: 500;
		letter-spacing: -.01em;
	}
	.button-wrap .btn .fa,
	.button-wrap .button-styled .fa {
		margin-left: 10px;
	}
	/* Checkbox Option */
	.checkbox-option input {
		display: none;
	}
	.checkbox-option label,
	.checkbox-option {
		cursor: pointer;
		display: flex;
		flex-wrap: nowrap;
		height: 60px;
		margin-bottom: 15px;
		margin-left: auto;
		margin-right: auto;
		box-shadow: 0px 3px 8px -5px #5f5f66;
		border: 1px solid;
		align-items: center;
		background: #eaeaea;
		border-color: #eaeaea;
		color: #000;
		text-decoration: line-through;
	}
	.checkbox-option .checkbox-icon {
		color: #acacac;
		display: block;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		text-align: center;
		display: flex;
		justify-content: center;
		align-items: center;
		opacity: 0.7;
	}
	.checkbox-option .checkbox-icon svg {
		height: 90px;
	}
	.checkbox-option .checkbox-icon svg,
	.checkbox-option .checkbox-icon svg path {
		fill: #acacac;
	}
	.checkbox-option .checkbox-text {
		text-transform: capitalize;
		font-size: 1.4rem;
		position: relative;
		width: 100%;
		font-weight: 300;
	}
	/* checked */
	.checkbox-option input:checked~label,
	.checkbox-option.checked {
		background: #032c4a;
		border-color: #032c4a;
		color: #FFF;
		text-decoration: none !important;
	}
	.checkbox-option  input:checked~label .checkbox-icon {
		opacity: 1;
	}
	.checkbox-option  input:checked~label .checkbox-icon svg,
	.checkbox-option  input:checked~label .checkbox-icon svg path {
		fill: #000000;
	}

	/* Checkbox-option Boolean */
	.checkbox-option.boolean label {
		width: 100%;
		max-width: none;
		height: 60px;
		margin-bottom: 0;
		background-color: transparent;
		border-color: transparent;
	}
	.checkbox-option.boolean +.checkbox-option.boolean label {
		background: none;
		border: none;
	}
	.checkbox-option.boolean .checkbox-text {
		position: relative;
		display: flex;
		align-items: center;
		font-size: 15px;
		line-height: 1.2em;
		color: #333;
		justify-content: start;
		width: 80%;
		letter-spacing: -0.05em;
	}
	.toggle-boolean {
		position: absolute;
		top: 7px;
		right: 0;
	}
	.toggle-boolean .toggle-box {
		display: inline-block;
		position: relative;
		width: 40px;
		height: 40px;
		border: 2px solid #b1b1b1;
		margin-left: 2.5px;
	}
	.toggle-boolean .icon {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		text-align: center;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.toggle-boolean .icon svg {
		width: 14px;
		height: 14px;
	}
	.toggle-boolean .true .icon {
		opacity: 0;
	}
	.toggle-boolean .true .icon svg,
	.toggle-boolean .true .icon path {
		fill: green;
	}
	.toggle-boolean .false .icon svg,
	.toggle-boolean .false .icon path {
		fill: red;
	}
	.toggle-boolean .text {
		display: block;
		text-align: center;
		position: relative;
		top: 5px;
		width: 100%;
		color: #d9d9d9;
	}
	/* check bool active */
	.checkbox-option.boolean input:checked~label  {
		background: transparent !important;
		border-color: transparent !important;
	}
	.checkbox-option.boolean input:checked~label .true .icon  {
		opacity: 1;
	}
	.checkbox-option.boolean input:checked~label .false .icon  {
		opacity: 0;
	}

	/*--------------------------------------------------------------
	| Measurement
	--------------------------------------------------------------*/
	.measurement-option {
		position:relative;
		display:flex;
		justify-content:center;
		flex-flow:wrap;
		margin:10px 0;
	}
	.measurement-option label {
		display:inline-block;
		padding:0 0 10px;
		color:#000;
		-webkit-box-sizing:border-box;
		-moz-box-sizing:border-box;
		box-sizing:border-box;
		width:49%;
		position:relative;
		overflow:hidden;
		background:#FFF;
		text-align: center;
		font-weight: 700;
	}
	.input-group-prepend {
			margin-right: 0px !important;
	}
	input.input_has_error {
		border: 1px solid red !important;
	}
	.input_has_error {
		border: 1px solid red !important;
	}
	.span_error {
		display:none;
		color:#dc3545;
		position:relative;
		margin:0 auto 0;
		text-align:center;
		padding:0;
		font-size:14px;
		line-height:14px;
	}
	#card-errors {
		color:#dc3545;
		position:relative;
		margin:-5px auto 0;
		text-align:center;
		padding:0 30px;
		font-size:14px;
		line-height:14px;
	}
	.span_error.has-error {
		display:block;
	}
	.form-control.dropdown-toggle,
	.form-control.dropdown-toggle:hover {
		color: #495057;
		text-decoration: none;
	}
	.form-control.dropdown-toggle::after {
		position: absolute;
		right: 20px;
		top: 45%;
	}
	.dropdown.as-select .dropdown-menu.show {
		width: 100%;
	}
	.dropdown.as-select .dropdown-menu.show .dropdown-item {
		font-size: 20px;
	}
	.form-control {
		height: auto;
		font-size: 16px;
		background: #ffffff;
		padding: 17px 15px;
		display: block;
		border-width: 0;
		margin: 0 auto 16px;
		text-align: center;
		border-radius: 8px;
		-webkit-appearance: none;
	}
	.contact .form-control {
		border: 1px solid #c5c4c4;
	}
	.form-control#card-name {
		border: 1px solid #c5c4c4;
	}
	.form-control:focus, input:focus {
		outline: none;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	::-webkit-input-placeholder {
		color:#848e98 !important;
	}
	::-moz-placeholder {
		color:#848e98 !important;
	}
	:-ms-input-placeholder {
		color:#848e98 !important;
	}
	:-moz-placeholder {
		color:#848e98 !important;
	}
	.form-group label {
		font-size: 1em;
		font-weight: 400;
	}
	.form-left-align .form-control {
		text-align: left;
	}
	.input-group-text {
		background-color: #ffffff;
		text-align: center;
		min-width: 40px;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		border-radius: 8px !important;
		color: #a2a4ad;
		border: 0px solid transparent;
	}
	.input-group {
		border-radius: 0px !important;
		border: 1px solid #d1d1d1;

		.form-control {
			margin-bottom: 0;
		}
	}

	/* StripeElement */
	.StripeElement {
		background-color: #fff;
		height: 60px;
		padding: 20px 15px;
		border: 1px solid #c5c4c4;
		border-radius: 8px;
		transition: box-shadow 150ms ease;
		-webkit-transition: box-shadow 150ms ease;
		transition: box-shadow 150ms ease;
	}
	#payment-request-button.StripeElement {
		background-color: #fff;
		height: auto;
		padding: 0;
		border: none;
		margin-bottom: 0;
	}
	.StripeElement--focus {
		border: 1px solid #8dbbf9;
	}
	.StripeElement--invalid {
		border-color: red;
	}
	.StripeElement--webkit-autofill {
		background-color: #fefde5 !important;
	}
	.card-details-https {
		display: block;
		text-align: center;
		margin-top:20px;
	}
	.card-details-https span {
		color:#00ce2a;
	}
	/* review-order */
	.review-order {
		max-width: 676px;
		margin: 0 auto;
	}
	.review-order h3 {
		margin-bottom: 10px;
		font-size: 1.7rem !important;
		font-weight: 200 !important;
		color: #001f35;
		border-bottom: 1px solid #0091ff;
	}
	.review-order .offer {
		font-size: 18px;
		margin-bottom: 0;
		color: #000;
		font-weight: bold;
	}
	.review-order .offer-note {
		font-size: 15px;
		color: #2e3035;
		text-transform: none;
		margin: 8px 0;
		font-weight: 300;
		padding-left: 10px;
	}
	.review-order .offer-price {
		font-weight: 400;
		font-size: 42px;
	}
	.review-order .offer-text {
		font-size: 18px;
		margin: 10px auto;
		background: yellow;
		display: inline;
		padding: 5px 10px;
	}

	.textteaser {
		text-transform:uppercase;
		font-size:20px;
		line-height:20px;
		margin:20px 0 0;
	}
	.textteaser span {
		padding:3px 5px;
		line-height:24px;
		font-size:18px;
		font-weight:300;
		color:inherit;
		text-align: center;
		border-right: none;
		display: block;
	}
	.textteaser div:last-child span {
		border-right:none;
	}
	.textteaser svg {
		width: 18px !important;
		fill: #00c297;
		position: relative !important;
		top: 2px;
		display: inline-block !important;
	}

	.guarantee {
		position: relative;
		padding: 20px 0 60px;
		background: #f9f9f9;
		border-top: 1px solid #f1f1f1;
	}

	.top-box {
		color: #fff;
		padding: 10px 5px;
		border: 1px solid #FFF;;
		background: transparent;
		border-radius: 20px;
	}
	.top-box.inverted {
		background: rgba(255, 255, 255, 0.8);
		color: #000;
		border: none !important;
	}
	.guarantee .top-box {
		background: #f9f9f9;
		color: #000;
		box-shadow: none !important;
		border-radius: 8px;
	}
	.secured-text {
		display: inline-block;
		text-align: center;
		color: inherit;
	}

	.success .box-details,
	.success h1 span, .success h2 span {
		color: #00c297;
	}
	.form-control-sm {
		padding: 12px 15px !important;
	}
	.contact-widgets .fa {
		font-size: 48px;
	}
	.contact-widgets .text {
		line-height: 20px;
	}
	.contact-widgets .text a {
		color: #212529;
	}
	.contact-widgets .c-label {
		font-weight: normal;
		font-size: 16px;
	}
	.g-recaptcha>div {
		margin: 0 auto;
	}
	.bars_menu.dropdown-toggle::after {
		display: none;
	}
	#paypal-button-container {
		height: 55px;
		overflow: hidden;
	}
	#disabled_modal .intro-logo {
		color: #fff;
		background: #000;
	}
	#disabled_modal .intro-logo g#MACROFARE-Color {
		fill: #fff;
	}

	// Checkbox Text
	.checkbox-text {
    text-transform: capitalize;
    font-size: 1.4rem;
    position: absolute;
    width: 100%;
    text-align: center;
    font-weight: 300;
    top: 0;
    justify-content: center;
    display: flex;
    left: 0;
    align-items: center;
		height: 100%;
		text-decoration: line-through;
	}
	.selected .checkbox-text{
		text-decoration: none;
	}

	.country-price-main {
		color: #000;
	}
	.promo-code-apply {
		background: #00c297;
		color: #fff !important;
		padding: 0 30px !important;
		border-color: #00c297 !important;
	}

	/*--------------------------------------------------------------
	| Responsive
	--------------------------------------------------------------*/
	@media (max-width: 991.98px) {
		.footer {
			padding: 44px 0 17px;
		}
		.footer li {
			display: inline-block;
			margin: 0 8px;
		}
		.footer .footer-title {
			display: none;
		}
		.second-footer-links {
			font-size: 10px;
		}
		.scroll-wrapper {
			-webkit-overflow-scrolling: touch;
			overflow-y: scroll;
		}
		.btn-getitnow {
			position: fixed !important;
			bottom: 0;
			width: 100%;
			border-radius: 0;
			left: 0;
			display: block;
			max-width: 100%;
			padding: 15px 0;
			z-index: 1000;
		}
		.footer {
			position: static;
			bottom: auto;
		}
		body {
			margin-bottom: 0;
		}
	}
	@media only screen and (min-device-width: 769px) {
		.btn-info:hover, .btn-info.btn-q:hover, .payment-btn:hover {
			background-color: #032c4a !important;
			border-color: #032c4a !important;
			color: #FFF !important;
		}
	}
	@media only screen and (max-device-width: 768px) {
		.btn-info.clicked,
		.btn-info.btn-q.clicked {
			background-color: #2e2e3c !important;
			border-color: #2e2e3c !important;
			color: #FFF !important;
		}

		.col-next:hover,
		.col-next:active,
		.col-next:focus {
			background: #018df0!important;
			border: 3px solid #018df0!important;
		}
		.col-next.clicked,
		.col-next.clicked:hover,
		.col-next.clicked:active,
		.col-next.clicked:focus {
			background-color: #0160a8!important;
			border: 3px solid #0160a8!important;

			.btn {
				outline: none!important;
				box-shadow: none!important;
			}
		}

		.col-back:hover,
		.col-back:active,
		.col-back:focus {
			background: #045795!important;
			border: 3px solid #045795!important;

			.btn {
				opacity: 0.7;
			}
		}
		.col-back.clicked,
		.col-back.clicked:hover,
		.col-back.clicked:active,
		.col-back.clicked:focus {
			background-color: #045795!important;
			border: 3px solid #045795!important;

			.btn {
				outline: none!important;
				box-shadow: none!important;
				opacity: 1;
			}
		}

		.select-gender .gender.female:hover {
		  background: #f60561 !important;
		}
		.select-gender .gender.female.clicked {
		  background: #b70247 !important;
		}
		.select-gender .gender.male:hover {
		  background: #0282c9 !important;
		}
		.select-gender .gender.male.clicked {
		  background: #035482 !important;
		}
	}
	@media (max-width: 767.98px) {
		.step-measurement {
			margin-bottom: 50vh!important;
		}
		.payment-accordion .card-header button h2 {
			font-size: 26px;
		}
		.payment-accordion .card-header button img {
			height: 44px !important;
		}
		.payment-accordion .card-header button[aria-expanded="false"]::before,
		.payment-accordion .card-header button[aria-expanded="true"]::before {
			left: 16px;
		}
		.payment-accordion .card-header button[aria-expanded="true"]::after {
			left: 20px;
		}
		.mobile-hor-layout .item {
			padding: 0px 0px 0px 105px;
			min-height: 120px;
			display: flex;
			justify-content: center;
		}
		.mobile-hor-layout .item .top,
		.mobile-hor-layout .item .bottom p,
		.mobile-hor-layout .item .bottom {
			text-align: left;
		}
		.mobile-hor-layout .item .middle {
			width: 150px;
			height: 150px;
			position: absolute;
			top: 50%;
			left: -25px;
			-ms-transform: translateY(-50%);
			-webkit-transform: translateY(-50%);
			-moz-transform: translateY(-50%);
			-o-transform: translateY(-50%);
			transform: translateY(-50%);
			background-image: none;
		}
		.table.table-sm-small td, .table.table-sm-small th {
			padding: .55rem .20rem;
			font-size: 14px;
		}
		.step-container.special {
			padding-bottom: 25vh;
		}
		.single_selection_btn {
			font-size: 1rem;
			height: 60px;
			padding-right: 2rem;

			&.btn-type-multi {
				height: 50px;
				margin-bottom: 10px;

				&::after {
					right: 20px;
				}
			}
		}

		.single_selection_btn::after {
			right: .6rem;
		}
		.step-container h3 {
			font-size: 1rem;
		}
		.checkbox-option label, .checkbox-option {
			height: 50px;
			margin-bottom: 10px;
		}
	}
	@media (max-width: 375px) {
		.dropdown.as-select .dropdown-menu.show .dropdown-item {
			font-size: 18px;
		}
	}
	@media (max-width: 325px) {
		.dropdown.as-select .dropdown-menu.show .dropdown-item {
			font-size: 14px;
		}
		.contact h2 {
			font-size: 1.7rem;
		}
		.payment-accordion .card-header button h2 {
			font-size: 20px;
		}
		.payment-accordion .card-header button img {
			height: 35px !important;
		}
		.order-benefits {
			font-size: 13px;
		}
		.single_selection_btn {
			font-size: .8rem !important;
		}
	}
	/* Small devices (landscape phones, 576px and up) */
	@media (min-width: 576px) {
		.textteaser span {
			text-align: left;
			border-right: none;
		}
		.banner.inner h1 {
			font-size: 32px;
		}
		.banner h3 {
			font-size: 28px;
			line-height: 32px;
			margin-bottom: 20px;
		}
		.btn-lg {
			font-size: 1.4rem;
		}
		.summary-item {
			margin: 10px;
		}
		.how-it-works svg {
		max-width: 155px;
		}
		.banner.checkout h3, .banner.checkout h3 span {
			font-size: 2.4rem;
		}
		.btn {
			font-size: 1.2rem;
		}
		.step-progress-button.show-btn {
		  max-width: 600px;
		}
	}
	/* Medium devices (tablets, 768px and up) */
	@media (min-width: 768px) {
		.jumbotron {
			padding: 5rem 0;
		}
		.textteaser span {
			text-align:center;
			border-right:1px solid;
		}
		.step-progress-button .btn {
			padding: 25px 0;
		}
		.extra-benefits li {
			flex: 1 0 50%;
		}
		body {
			font-size: 18px;
		}
		h1.step-headline {
			margin: 35px auto;
		}
		.acesss-box svg {
			margin-bottom: 0 !important;
		}
		.checkbox-option.boolean .checkbox-text {
		font-size: 20px;
		}
		.box-details .form-group {
			padding-right:150px;
		}
		.box-details .form-group .btn {
			position:absolute;
			right:10px;
			top:10px;
			width:130px;
			margin-top: 0;
		}
		h3.heading {
			font-size: 32px;
		}
		.final h1 {
			font-size: 36px;
		}
		.select-gender .icon {
			height: 260px;
		}
		.offer-box {
			font-size: 1.7rem;
			line-height: 2rem;
		}
		h1.home-title {
			font-size: 48px;
		}
		.item .top {
			font-size: 20px;
		}
		.benefits h1 {
			font-size: 3.6rem;
		}
		h1.instant-access {
			font-size: 42px;
		}
		.options h1 {
			font-size: 3.2rem;
		}
		.step-container h3 {
			font-size: 1.6rem;
		}
		.step-container h2 {
			font-size: 1.8rem;
		}
		.final {
			background-size: 35%;
		}
		.item p {
			font-size: 1.1rem;
		}
		.mobile-hor-layout:before {
			left: 75%;
		}
		.how-it-works {
			padding-bottom: 160px;
		}
		.intro {
			background-image: url(../images/food-cover-lrg.jpg);
		}
	}

	/* Large devices (desktops, 992px and up) */
	@media (min-width: 992px) {
		.step-container {
			padding-bottom: 80px;
		}
		.banner.inner h1 {
			font-size: 1.8rem;
			text-transform: uppercase;
			line-height: 1.8rem;
		}
		.banner h3 {
			font-size: 32px;
			line-height: 46px;
			margin-bottom: 40px;
		}
		.step-progress {
			padding: 30px;
		}
		.step-number {
			margin: 40px auto;
		}
		.step-headline {
			font-size: 42px;
		}
		.footer {
			font-size: 16px;
		}
		.second-footer-links li {
			margin-bottom: 0;
		}
		.footer ul {
			margin-bottom: 1rem;
		}
		.success h1 {
			font-size: 60px;
			line-height: 82px;
		}
		.modal-body {
			font-size:18px;
			line-height:20px;
		}
		.daylabel:after {
			position: absolute;
			content: "\f0da";
			font-family: 'Font Awesome 5 Free';
			font-size: 28px;
			display: inline-block;
			top: 0;
			right: -50px;
		}
		p {
			font-size: 1em;
		}
		.btn-getitnow:after {
			display: none;
		}
		.benefits p {
			font-size: 1.8rem;
			line-height: 2.4rem;
		}
		.summary-final, .step-container.payment {
			padding-bottom: 120px;
		}
		.col-back, .col-next {
			position: relative;
		}
		.how-it-works {
			padding-bottom: 250px;
		}
		.step-progress-button.show-btn {
		  max-width: 650px;
		}
	}
	/* Extra large devices (large desktops, 1200px and up) */
	@media (min-width: 1200px) {
		.summary-item {
			width: 250px;
		}
		.step-progress-button.show-btn {
		  max-width: 760px;
		}
	}
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
	.step-container {
		padding-bottom: 80px;
	}
}