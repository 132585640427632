.quiz-page {
  .payment-accordion .card-header {
    padding: 0;
    border-color: #c5c4c4;
  }
  .payment-accordion .card-header h4 {
    font-weight: 400;
  }
  .payment-accordion .card-header button {
    padding: 7px 15px 7px 45px;
    min-height: 62px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    color: #000;
    text-decoration: none;
    background-color: #fff;
    position: relative;
  }
  .payment-accordion .card-header button[aria-expanded="false"]::before,
  .payment-accordion .card-header button[aria-expanded="true"]::before,
  .payment-accordion .card-header button[aria-expanded="false"]::after,
  .payment-accordion .card-header button[aria-expanded="true"]::after {
    content: '';
    position: absolute;
    display: block;
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
  }
  .payment-accordion .card-header button[aria-expanded="false"]::before,
  .payment-accordion .card-header button[aria-expanded="true"]::before {
    transition: background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    top: 22px;
    left: 20px;
    pointer-events: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .payment-accordion .card-header button[aria-expanded="true"]::after {
    background-color: #fff;
    width: .5rem;
    height: .5rem;
    top: 26px;
    left: 24px;
  }
  .payment-accordion .card-header button[aria-expanded="false"]::before {
    background-color: #dee2e6;
  }
  .payment-accordion .card-header button[aria-expanded="true"]::before {
    background-color: #007bff;
  }
  .payment-accordion .card-body {
    border-color: #c5c4c4;
    background-color: #fafafa;
  }

  .input_has_error {
		border: 1px solid red !important;
	}
}