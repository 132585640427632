.loading {
  width:60px;
  height:60px;
  border-radius:150px;
  border:8px solid #00ffc8;
  border-top-color:rgba(0,0,0,0.3);
  box-sizing:border-box;
  position:relative;
  animation:loading 1.2s linear infinite;
  -webkit-animation:loading 1.2s linear infinite;
  display: block;
  margin: 0 auto 10px;
}
@keyframes loading{
  0%{transform:rotate(0deg)}
  100%{transform:rotate(360deg)}
}
@-webkit-keyframes loading{
  0%{-webkit-transform:rotate(0deg)}
  100%{-webkit-transform:rotate(360deg)}
}