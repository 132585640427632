.error-box {
	background: #404347;
	background-image: url(../../images/food-cover-mobile.jpg);
	background-size: cover;
	background-position: top;
	background-repeat: no-repeat;
	background-blend-mode: multiply;
	height: 100%;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	padding: 50px 0;

  &>.container,
  &>.container>.row {
    min-height: 100vh;
    height: 100%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;

    &>div {
      -ms-flex-item-align: start!important;
      align-self: start!important;

      &.small-box {
        -ms-flex-item-align: center!important;
        align-self: center!important;
      }
    }
  }
}

.disabled-box {
	background-color: #fff;
  position: relative;

  .box-heading {
    background-color: #000;
    text-align: center;
    color: #fff;
    padding: 1rem;

    h3 {
      margin-bottom: 0;
    }

    #MACROFARE-Color {
      fill: #fff;
    }
  }

  h2 {
    font-weight: bold;
  }

  h3 {
    font-size: 20px;
  }
}
@media (min-width: 992px) {
	.error-box {
		background-image: url(../../images/food-cover-lrg.jpg);
		padding: 0;
	}
	.error-box>.container>.row>div {
		-ms-flex-item-align: center!important;
		align-self: center!important;
	}
	.disabled-box h2 {
		font-size: 2rem;
	}
	.disabled-box h3 {
		font-size: 24px;
	}
}