.accordion-style {
  position: relative;

  .card {
    border: none;

    &.active {
    .card-header:after {
        content: '-';
        color: $accordion-icon-color;
      }
    }
  }

  .card-header {
    cursor: pointer;
    position: relative;
    display: block;
    border-top: 1px solid $accordion-card-header-bordercolor;
    border-bottom: none;
    margin-bottom: 8px;
    background-color: transparent;
    font-family: 'Montserrat', sans-serif;
    font-size: 24px;
    display: block;
    letter-spacing: -.03rem;
    font-weight: 300;

    &::after {
      content: '+';
      font-size: 22px;
      color: $accordion-icon-color;
      position: absolute;
      width: 24px;
      height: 24px;
      transition: all 0.3s ease;
      right: 0;
      top: 50%;
      @include translateY;
      font-style: normal;
      font-size: 26px;
      line-height: 26px;
      font-weight: 700;
      text-align: center;
    }
  }

  .card-body {
    padding: 7px 25px 20px 25px;
    font-weight: 300;
  }
}