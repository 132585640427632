.section-steps {
	padding-bottom: 2rem;
	width: 100%;
}
.progress-wrap {
	width: 100%;
}
.step-progress {
	padding: 30px 5px 20px;
	height: auto;
	border-radius: 0;
	max-width: 525px;
	margin: 0 auto;
	color: inherit;
	position: relative;
	z-index: 5;
	display: flex;
	justify-content: space-around;
	overflow: hidden;
	align-items: center;

	.progress-line {
		height: 4px;
		padding: 0;
		width: 100%;
		background: #efefef;
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box;
		position: relative;

		.step-line {
			background: #5c5c61;
			width: 0;
			height: 4px;
			display: block;
			position: relative;
			top: 0;

			&::after {
				content: '';
				display: none;
				width: 100%;
				height: 4px;
				background-color: #dee3e6;
			}
		}
	}

	.progress-point {
		position: relative;
		list-style: none;
		margin: 0;
		padding: 0;
		width: 100%;
		padding: 0;
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box;
		z-index: 2;
		display: flex;
		justify-content: space-between;
		align-items: center;

		li {
			white-space: nowrap;
			position: relative;
			cursor: pointer;
			top: 10px;

			&:hover {
				background-color: #8a898c;
				border-color: #8a898c;

				span {
					opacity: 1;
					color: #2f2f2f;
				}
			}

			&.active span {
				opacity: 1;
				color: inherit;
				position: absolute;
				display: inline;
				padding: 2px 10px;
				left: 0;
				z-index: 5;
				min-height: 35px;
				font-weight: 400;
			}

			&>span {
				position: absolute;
				text-align: center;
				opacity: 0;
				top: -35px;
				-webkit-transform: translateX(-45%);
				transform: translateX(-45%);
				color: #ddd;
				pointer-events: none;
				font-size: 12px;
				font-weight: 700;
				text-transform: uppercase;
			}
		}

		.previous {
			pointer-events: none;
			opacity: 0;
			background-color: transparent;
			border-color: transparent;
		}
	}
}