@import '../abstracts/variables';

.vip-package {
  border: 2px dashed $offer-box-theme;
  overflow: hidden;
  position: relative;
  background: transparent;
  color: #000;
  border-radius: 20px;

  ul li {
    font-size: 1.2rem;
    margin-bottom: 15px;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    line-height: 24px;

    svg {
      max-width: 55px;
      position: relative;
      fill: $offer-box-theme !important;
      margin-right: 15px;
      float: left;
      width: 100%;

      .fil0 {
        fill: $offer-box-theme !important;
      }
    }
  }

  .corner-ribbon {
    width: 200px;
    background: $offer-box-theme;
    position: absolute;
    text-align: center;
    line-height: 36px;
    letter-spacing: 1px;
    color: #FFF;
    top: 10px;
    right: -65px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    font-size: 20px;
  }

  .offer-box {
    background: $offer-box-theme;
    text-align: center;
    position: relative;
    margin: 1.5rem -1rem -1rem;
    color: #FFF;
    font-size: 1.5rem;
    line-height: 1.8rem;
    letter-spacing: 2px;
    padding: 10px 5px;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .offer-box {
    font-size: 1.7rem;
    line-height: 2rem;
  }

  .corner-ribbon {
    line-height: 50px;
    top: 25px;
    right: -50px;
  }

  .vip-headline h1 {
    font-size: 2.6rem;
  }
}