// -----------------------------------------------------------------------------
// This file contains styles that are specific to the mealplan page.
// -----------------------------------------------------------------------------
.quiz-page {
	/*--------------------------------------------------------------
	| Final
	--------------------------------------------------------------*/
	.final {
		text-align: center;
		height: auto;
		position: relative;
		overflow: hidden;
		z-index: 1;
		padding: 80px 0 40px;
		background: #e8ebf0;
		background-image: url(../images/summary-bg.jpg);
		background-size: 50%;
		background-position: top right;
		/* color: #FFF; */
		background-repeat: no-repeat;

		p {
			font-weight: 400;
		}

		h1 {
			line-height: 2.8rem;
			font-size: 2.2rem;
			color: inherit;
			font-weight: 900;

			span {
				color: #00c297;
			}
		}

		.btn {
			max-width: 325px;
			font-size: 18px;
			padding: 15px;
			min-height: 0;
			width: 90%;
		}
	}

	.example {
		position: relative;
		overflow: hidden;
		padding: 80px 0;
		z-index: 0;
		background-blend-mode: multiply;
		color: #FFF;
	}
	.example-bg {
		background: #2d3231;
		position: fixed;
		background-image: url(../images/final-male-reduced.jpg);
		z-index: -1;
		height: 100vh;
		width: 100%;
		background-size: cover;
		background-position: center 30%;
		background-blend-mode: multiply;
	}
	.example svg {
		max-height: 60px;
	}
	.example ul {
		list-style: none;
		color: #00ffc8;
		line-height: 2.5em;
		padding-left: 0;
		text-transform: uppercase;
	}
	.example ul li span {
		color: #FFF !important;
		text-transform: none;
	}
	.example ul li:before {
		position: relative;
		content: "\f274";
		font-family: 'Font Awesome 5 Free';
		display: inline-block;
		padding-right: .5em;
		padding-left: .5em;
		color: inherit;
	}
	.menubox {
			background: rgba(0, 0, 0, .5);
	}
	.skills {
		position: relative;
		overflow: hidden;
		padding: 40px 0;
		color: #000;
		background: linear-gradient(#FFF, #e8ebf0);
		background: -webkit-linear-gradient(#FFF, #e8ebf0);
	}
	.skills h3 {
		font-size: 24px;
		position: relative;
		z-index: 1;
		font-weight: 900;
		margin-bottom: 20px;
		color: #2e2f30;
	}
	.skill-wrap {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		justify-content: center;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}
	.access-box {
		border: 1px solid #e5e5e8;
		padding: 30px 10px;
		color: inherit;
		border-radius: 20px;
	}

	.access-box p span {
		font-weight: 900;
	}
	.access-box p {
		font-weight: 300;
		color: #2e2f30;
	}
	.mobile-device {
		position: relative;
		width: 215px;
		height: 425px;
		margin: 0 auto 25px;
	}
	.mobile-device .mobile-frame  {
		width: 215px;
		height: 425px;
		z-index: 2;
	}
	.mobile-device::before, .mobile-device::after {
		display: block;
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		z-index: -1;
	}
	.mobile-screen {
		width: 191px;
		height: 404px;
		background-size: 191px 404px;
		background-repeat: no-repeat;
		margin-left: 12px;
		margin-top: 10px;
	}
	.mobile-device.menu .mobile-screen {
		background-image: url(../images/menu-glass.jpg);
	}
	.mobile-device.coach .mobile-screen {
		background-image: url(../images/coach-glass.jpg);
	}
	.mobile-device.recipe .mobile-screen {
		background-image: url(../images/recipe-glass.jpg);
	}
	.mobile-device.quiz .mobile-screen {
		background-image: url(../images/quiz-glass.jpg);
	}
	.mobile-device.grocery .mobile-screen {
		background-image: url(../images/grocery-glass.jpg);
	}
	.mobile-frame {
		background-size: 215px 425px;
		background-repeat: no-repeat;
		background-image: url(../images/mobile-phone.jpg);
		-webkit-mask-repeat: no-repeat;
		mask-repeat: no-repeat;
		-webkit-mask-image: url(../images/mobile-bezel.svg);
		mask-image: url(../images/mobile-bezel.svg);
	}
	.mobile-frame, .mobile-screen {
		position: absolute;
		top: 0;
		left: 0;
	}
	svg #lightningbolteps10 g path {
		fill: #ffb200 !important;
	}
	.graph-descriptions {
		position: relative;
		color: #000;
	}
	.summary-item {
		position: relative;
		width: 300px;
		height: auto;
		max-width: 100%;
		margin: 20px 0;
		box-shadow: 0 35px 30px -29px rgba(18, 18, 18, 0.6);
		color: #fff;
		background: rgba(2, 67, 52, 0.6);
	}
	.calories {
		background: #f6f6f6;
		height: auto;
		overflow: hidden;
		z-index: 1;
		padding: 40px 0;
		position: relative;
	}
	.calories-before {
		background: #e6ebef;
		height: auto;
		overflow: hidden;
		z-index: 1;
		position: relative;
		padding: 20px 0;
		color: #000;
	}
	.calories-final {
		background: #202227;
		color: #FFF;
		height: auto;
		overflow: hidden;
		z-index: 1;
		position: relative;
		padding: 35px 0;
	}
	.calories-final h3 {
		line-height: 2.2rem;
	}
	.calories h1 {
		font-size: 2.2rem;
	}

	.item {
		display: flex;
		flex-wrap: nowrap;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		color: inherit;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		justify-content: space-between;
		height: 100%;
		margin: 0 auto;
		border: 1px solid #ffffff;
		padding: 60px 10px 25px;
		border-radius: 20px;
		box-shadow: 0 12px 78px -33px rgb(29, 31, 33);
		background: #FFF;
	}
	.item .info-btn {
		display: block;
		width: 40px;
		height: 40px;
		font-size: 18px;
		position: absolute;
		top: 20px;
		right: 32px;
		color: inherit;
		line-height: 40px;
		z-index: 100;
		text-align: center;
		border-radius: 15px;
		border: none;
		box-shadow: 0px 2px 26px -12px #000;
		background-color: #fff;
		cursor: pointer;
	}
	.item .info-btn:focus {
		outline: none;
	}
	.item .top {
		font-size: 16px;
		line-height: inherit;
		text-align: center;
		font-weight: 600;
		letter-spacing: -.06rem;
	}
	.item .top.goal {
		font-size: 1.8rem;
		line-height: inherit;
		text-align: center;
		font-weight: 700;
		letter-spacing: -.06rem;
	}
	.item .middle {
		display: -webkit-inline-box;
		display: -ms-inline-flexbox;
		display: inline-flex;
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		justify-content: center;
		-ms-flex-item-align: center;
		align-self: center;
		background-image: url(../images/circle-bg.svg);
		background-repeat: no-repeat;
		width: 100%;
		position: relative;
	}
	.item .middle.macros {
		background-image: none;
	}
	.item  .middle figure {
		margin: 0!important;
	/*	width: 260px;
		height: 260px;*/
		width: 100%;
		height: 0;
		padding-top: 100%;
	}
	.item  .middle figure svg {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
	.item p {
		margin: 0;
		text-align: center;
		font-weight: 300;
		font-size: 1rem;
	}
	.bmi-result {
		font-style: normal;
		font-weight: 700;
		font-size: 1.3rem;
		color: #00d2a0;
	}
	.text-red {
		color:#ff2727;
	}
	.text-yellow {
		color:#FAD961;
	}
	.text-green {
		color:#3bd800;
	}
	.text-orange {
		color:#fd7e14;
	}

	/* Long List */
	.long-list {
		background-image: url(../images/cube-white.png);
		background-repeat: repeat;
		height: auto;
		position: relative;
		overflow: visible;
		background-blend-mode: multiply;
		padding: 100px 0;
		font-size: 16px;
		line-height: 2em;
	}
	.long-list .container:before {
		content: '';
		display: table;
	}
	.long-list .container:after {
		content: '';
		clear: both;
	}

	.summary::before, .shipping::before, .payment::before {
		position: absolute;
		width: 0;
		height: 0;
		border-left: 90px solid transparent;
		border-right: 90px solid transparent;
		border-top: 30px solid #e8ebf0;
		content: '';
		left: 50%;
		margin-left: -90px;
		top: 0;
	}
	.shipping:before,.payment:before {
		border-top: 30px solid #2b3035;
	}
	.summary-macro:before {
		border-top: 30px solid #202227;
	}

	.main-intro:before {
		border-top: 40px solid #f7f7f7;
	}

	.summary {
		position: relative;
		padding: 35px 0;
		overflow: hidden;
		background: #202227;
		color: #FFF;
	}
	.summary-macro {
		position: relative;
		padding: 0 0 60px;
		overflow: hidden;
		background: #e8ebf0;
		color: #000;
	}
	.summary-top {
		position: relative;
		padding: 40px 0 0;
		overflow: hidden;
		background: #FFF;
	}
	.summary h2 {
		font-size: 2.8rem;
		line-height: 2.8rem;
		max-width: 90%;
		font-weight: 900;
	}
	.summary h2 span {
		font-weight: 200;
	}
	.summary-top ul {
		list-style: none; /* Remove default bullets */
	}
	.summary-top ul li::before {
		content: "\2022";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
		color: red; /* Change the color */
		font-weight: 900; /* If you want it to be bold */
		display: inline-block; /* Needed to add space between the bullet and the text */
		width: 1em; /* Also needed for space (tweak if needed) */
		margin-left: -1em; /* Also needed for space (tweak if needed) */
	}
	span.red {
		background: #00d2a0;
		border-radius: 0.8em;
		-moz-border-radius: 0.8em;
		-webkit-border-radius: 0.8em;
		color: #ffffff;
		display: inline-block;
		font-weight: bold;
		line-height: 1.2em;
		margin-right: 5px;
		text-align: center;
		width: 1.4em;
		height: 1.4em;
	}
	span.blue {
		background: #0083ce;
		border-radius: 0.8em;
		-moz-border-radius: 0.8em;
		-webkit-border-radius: 0.8em;
		color: #ffffff;
		display: inline-block;
		font-weight: bold;
		line-height: 1.2em;
		margin-right: 5px;
		text-align: center;
		width: 1.4em;
		height: 1.4em;
	}

	h2.start-headline {
		font-weight: 300;
		width: 100%;
	}

	.benefits {
		color: #fff;
		position: relative;
		padding: 40px 0 80px;
		overflow: hidden;
		background: #2c2d2f;
		background-image: url(../images/macrofare.jpg);
		background-repeat: no-repeat;
		background-position: 67% center;
		background-size: cover;
		background-blend-mode: overlay;
	}
	.benefits.headline {
		background-image: none;
		padding: 35px 0;
		background: #FFF;
		color: #000;
	}
	.benefits h1 {
		font-size: 2.4rem;
	}
	.benefits p {
		line-height: 2rem;
		font-size: 1.4rem;
		font-weight: 400;
		color: #FFF;
	}
	.benefits p span {
		font-weight: 700;
	}
	.benefits h3 span {
		color: #ff602d;
	}

	.intro-logo h3 {
		color: inherit;
		font-size: 1.3em;
		font-weight: 300;
	}
	.long-list .heading1 {
		margin: 80px auto 60px;
		font-weight: 100;
		font-size: 40px;
		color: #FFF;
	}
	.long-list .heading2 {
		margin: 60px auto;
		font-weight: 100;
		font-size: 32px;
		color: #ff6602;
	}
	.long-list .heading3 {
		margin: 60px auto;
		font-weight: 100;
		font-size: 24px;
		color: #FFF;
	}
	.long-list .text-vertical {
		position: relative;
		z-index: 10;
		display:flex;
	}
	.long-list .text-vertical p {
		transform: rotate(90deg);
		text-transform: uppercase;
		position: relative;
		font-weight: 900;
		color: #ff6603;
		font-size: 20px;
		top: 38px;
		align-self: center!important;
		justify-content: flex-end;
		transform-origin: right bottom;
		left: 50%;
	}
	.center {
		text-align: center;
	}
	/* body-fuel */
	.options {
		color: #fff;
		position: relative;
		padding: 60px 0;
		overflow: hidden;
		background: #2c2d2f;
		background-image: url(../images/intro-male-lrg.jpg);
		background-repeat: no-repeat;
		background-position: 60% center;
		background-size: cover;
		background-blend-mode: overlay;
	}
	.options h1 {
		font-size: 1.8rem;
	}
	.body-fuel .row-box {
		border: 2px solid #00ffc8;
		padding: 20px 5px;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		margin: 10px 5px;
	}
	.body-fuel ul {
		color: #00ffc8;
		font-weight: bold;
	}
	.img-box img {
		z-index: 1;
		position: relative;
		margin: 0 auto;
		width: 100%;
		max-width: 400px;
		display: block;
	}
	.img-box:before {
		position: absolute;
		top: -12%;
		right: 0;
		bottom: -12%;
		left: 0;
		content: "";
		background-color: #00ffc8;
		width: 50%;
		max-width: 250px;
		margin: 0 auto;
	}
	/* hunger */
	.hunger {
		background: #2f2f2f;
		background-image: url(../images/starvation.jpg);
		background-size: cover;
		background-repeat: no-repeat;
		background-position: 90% center;
		padding: 80px 0;
		position: relative;
		background-blend-mode: overlay;
		color: #FFF;
	}
	.hunger h3 {
		color: #FFF;
		font-size: 36px;
		text-transform: none;
	}

	/* extra benefits */
	.extra-benefits {
		background: #efefef;
		padding: 0 0 40px;
		position: relative;
	}
	.extra-benefits h1 {
		font-weight: 400;
		text-transform: uppercase;
		font-size: 52px;
	}
	.extra-benefits ul {
		font-size: 20px;
		line-height: 2.5em;
		text-transform: uppercase;
		list-style: none;
		display: flex;
		flex-wrap: wrap;
		margin-bottom: 20px;
	}
	.extra-benefits li {
		flex: 1 0 100%;
		font-size: 16px;
	}
	.extra-benefits li:before {
		content: "\f00d";
		font-family: 'Font Awesome 5 Free';
		display: inline-block;
		margin-left: -1.5em;
		width: 1.5em;
		color: red;
	}

	ul.remove, ul.add, ul.risks {
		list-style: none;
		display: flex;
		flex-wrap: wrap;
		height: 100%;
	}
	ul.remove li, ul.add li, ul.risks li {
		flex: 1 0 100%;
	}
	.remove li:before {
		content: "\f068";
		font-family: 'Font Awesome 5 Free';
		display: inline-block;
		margin-left: -1.5em;
		width: 1.5em;
		color: #dc3545;
	}
	.add li:before {
		content: "\f067";
		font-family: 'Font Awesome 5 Free';
		display: inline-block;
		margin-left: -1.5em;
		width: 1.5em;
		color: #28a745;
	}

	.risks li:before {
		content: "\f05b";
		font-family: 'Font Awesome 5 Free';
		display: inline-block;
		margin-left: -1.5em;
		width: 1.5em;
		color: #00ffc8;
	}

	/* groceries box */
	.groceries {
		background: #f7f7f7;
		position: relative;
		padding: 80px 0;
		z-index: 0;
		overflow: hidden;
	}

	.groceries:before {
		position: absolute;
		width: 0;
		height: 0;
		border-left: 40px solid transparent;
		border-right: 40px solid transparent;
		border-top: 40px solid #FFF;
		content: '';
		left: 50%;
		margin-left: -40px;
		top: 0;
		z-index: 1;
	}
	 .access-box svg {
		max-width: 60px !important;
		margin: 0 auto;
		display: block !important;
		height: auto !important;
	}
	.box svg g, .access-box svg g {
		fill: #00ffc8;
	}
	.top-box svg {
		max-width: 60px !important;
	}
	.box .highlight-bg:after {
		background-color: #00c297;
	}
	.box .h5, .access-box .h5 {
		color: #07ffc8;
	}
	.h5.bonus {
		color: #ff602d !important;
	}

	p.arrow-text {
		transform: rotate(90deg) translateX(-40%);
		text-transform: uppercase;
		position: relative;
		font-weight: 400;
		font-size: 20px;
		align-self: center!important;
		justify-content: flex-end;
		transform-origin: left;
		left: 50%;
		margin-top: 60px;
		color: inherit;
	}
	h1.instant-access {
		font-size: 32px;
		font-weight: 300;
		color: inherit;
	}
	.highlight-bg {
		position: relative;
		color: inherit !important;
		z-index: 1;
	}
	.highlight-bg:after {
		width: 100%;
		height: 25%;
		position: absolute;
		left: 0;
		background: #00d2a0;
		content: '';
		bottom: 10%;
		z-index: -1;
	}
	.highlight-bg.calorie:after {
		background: #cdf7e8;
	}
	.highlight-bg.fat:after {
		background: #ccfcff;
	}
	.highlight-bg.protein:after {
		background: #ffeacc;
	}
	.highlight-bg.carbs:after {
		background: #fdcdca;
	}
	.extra-benefits h3 {
		margin-top: 0;
		font-size: 18px;
		line-height: 22px;
		text-transform: uppercase;
		color: #000;
	}
	.step-number {
		text-align: center;
		background: #00ffc8;
		border-radius: 50%;
		width: 60px;
		height: 60px;
		line-height: 60px;
		font-size: 32px;
		color: #FFF;
		margin: 30px auto;
	}
	.step-headline {
		margin: 0px auto 20px;
		color: inherit;
		font-weight: 400;
		font-size: 36px;
	}
	/* meal planning */
	.meal-planning-counter {
		position: relative;
		padding: 40px 0;
		z-index: 0;
		background: #ffcc31;
	}
	h1.explanation {
		font-size: 60px;
		font-weight: 100;
		text-transform: uppercase;
	}
	.goal-bg {
		background: #d6fff5;
	}
	.current-bg {
		background: #008165;
			color: #FFF;
	}
	.goal-bg::before {
		position: absolute;
		width: 0;
		height: 0;
		border-bottom: 35px solid transparent;
		border-top: 35px solid transparent;
		border-left: 18px solid #008165;
		content: '';
		top: 50%;
		margin-top: -35px;
		left: 0;
	}
	/* summary final */
	.summary-final {
		position: relative;
		overflow: hidden;
		padding: 40px 0;
		color: #FFF;
		background: linear-gradient(#202227, #1a1a1a);
		background: -webkit-linear-gradient(#202227, #1a1a1a);
	}
	.summary-final h3 {
		color: #FFF;
	}
	.banner.inner svg {
		width: 152px;
	}
	.logo.inverted svg {
		width: 110px;
	}
	.summary-final svg g #MACROFARE-Color, .logo.inverted svg g #MACROFARE-Color {
		fill: #FFF !important;
	}
	.benefits svg g #MACROFARE-Color {
		fill: #FFF;
	}
	.buy-box {
		box-shadow: 0 20px 60px -12px rgba(0, 0, 0, 0.2);
		padding: 30px 10px;
		border-radius: 20px;
		border: 1px solid #00ffc8;
		background: #ffffff12;
		text-align: center;
	}
	.price-big {
		font-weight: 400;
		margin: 50px 0;
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
	}
	.price-big .main-price {
		font-size: 102px;
		line-height: 102px;
		color: #ffaa00;
	}
	.price-big .sup-text {
		font-size: 32px;
		line-height: 32px;
		text-align: left;
		padding-top: 7px;
		padding-left: 17px;
		color: #b3b3b3;
	}
	.price-big .sup-text em {
		margin-left: -17px;
		color: #ffaa00;
		font-style: normal;
		font-size: 56px;
		line-height: 50px;
	}
	.final-statement {
		padding: 40px 0;
		position: relative;
	}
	.final-statement h2 {
		color: #2f2f2f;
		font-weight: 400;
		text-transform: uppercase;
		font-size: 36px;
		margin: 20px 0;
		text-shadow: none;
	}
	.main-intro {
		padding: 80px 0;
		color: #29292B;
		position: relative;
		overflow: hidden;
	}
	.benefits svg, .skills svg, #get-it-now svg {
		margin: 0 auto;
		width: 100%;
		height: auto;
		max-width: 285px;
		display: block;
	}
	.benefits svg {
		max-width: 285px;
	}
	.usa svg {
		max-width: 60px !important;
		margin: 0 auto;
		width: 100%;
		height: auto;
		display: block;
	}
	.usa .inverted svg path {
		fill: #FFF !important;
	}

	/*--------------------------------------------------------------
	| SVG animated
	--------------------------------------------------------------*/
	.html {
		top:50px;
		left:45px;
	}
	.html + svg .outer {
		stroke:#e34f26;
	}
	.outer {
		fill:transparent;
		stroke:#00ffc8;
		stroke-width:15;
		stroke-dasharray:534;
		-webkit-animation-play-state:running;
	/* firefox bug fix - won't rotate at 90deg angles */
		-moz-transform:rotate(-89deg) translateX(-190px);
	}
	.outer.bmr {
		stroke:#00ebfa;
	}
	.outer.water {
		stroke:#00b5ff;
	}
	.outer.calorie {
		stroke:#00ffc4;
	}
	.outer.fat {
		stroke:#00d69d;
	}
	.outer.protein {
		stroke:#ffa800;
	}
	.outer.carbs {
		stroke:#cb2507;
	}
	.outer.milestone {
		stroke:#00ffc8;
	}
	/* END Circle colors and graphic positions */
	/* Set the initial values for the animation */
	.chart[data-percent='100'] .outer {
		stroke-dashoffset:72;
	}
	.chart[data-percent='95'] .outer {
		stroke-dashoffset: 9;
		stroke-dasharray: 40;
	}
	.chart[data-percent='75'] .outer {
		stroke-dashoffset:175;
		stroke-dasharray: 40;
	}
	.chart[data-percent='70'] .outer {
		stroke-dashoffset:950;
	}
	.chart[data-percent='50'] .outer {
		stroke-dashoffset:267;
	}
	.chart[data-percent='25'] .outer {
		stroke-dashoffset:401;
	}
	.chart[data-percent='20'] .outer {
		stroke-dashoffset:450;
	}
	.chart[data-percent='5'] .outer {
		stroke-dashoffset:500;
	}
	.chart[data-percent='100'] .outer.opacity {
		stroke-dashoffset:0;
	}
	.chart[data-percent='95'] .outer.opacity {
		stroke-dashoffset:35;
	}
	.chart[data-percent='75'] .outer.opacity {
		stroke-dashoffset:0;
	}
	.chart[data-percent='70'] .outer.opacity {
		stroke-dashoffset:0;
	}
	.chart[data-percent='50'] .outer.opacity {
		stroke-dashoffset:0;
	}
	.chart[data-percent='25'] .outer.opacity {
		stroke-dashoffset:0;
	}
	.chart[data-percent='20'] .outer.opacity {
		stroke-dashoffset:0;
	}
	.chart[data-percent='5'] .outer.opacity {
		stroke-dashoffset:0;
	}

	/*--------------------------------------------------------------
	| Progress Bar
	--------------------------------------------------------------*/
	.progressBar {
		margin: 15px auto;
		width: 100%;
		display: inline-block;
		font-weight: 400;
	}
	.progressBar .value,
	.progressBar .header {
		font-weight: 300;
		margin-bottom: 4px;
		font-size: 18px;
	}
	.progressBar .header {
		padding-left: 2px;
	}
	.progressBar .value {
		float: right;
		margin-top: -30px;
	}
	.progressBar .bar {
		width: 100%;
		height: 18px;
		-webkit-border-radius: 8px;
		-webkit-background-clip: padding-box;
		-moz-border-radius: 8px;
		-moz-background-clip: padding;
		border-radius: 8px;
		background-clip: padding-box;
		background-color: #e6ebef;
	}
	.progressBar .bar div {
		height: 100%;
		width: 100%;
		background-color: #00ffc8;
		-webkit-border-radius: 8px;
		-webkit-background-clip: padding-box;
		-moz-border-radius: 8px;
		-moz-background-clip: padding;
		border-radius: 8px;
		background-clip: padding-box;
		max-width: 100%;
	}
	.progressBar .header span {
		font-size:20px;
		display:inline;
	}
	.progressBar .value {
		color:#FFF;
		display: none;
	}
	.payment-btn .fa {
		margin-left: 10px;
	}
	.nav-link {
		color: #ff602d !important;
		font-size: 24px !important;
	}
}

.female-theme .example-bg {
	background-image: url(../images/final-female-reduced.jpg);
	background-position: center 25%;
}
.female-theme .options {
	background-image: url(../images/intro-cover-lrg.jpg);
}