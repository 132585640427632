@import '../abstracts/variables';

.footer {
	background: $footer-bgcolor;
	width: 100%;
	min-height: 79px;
	padding: 64px 0 27px;
	font-size: 14px;
	color: $footer-color;

	a, .btn-text {
		color: $footer-link-color;
		text-decoration: none;

		&:hover,
		&:hover .blog-date {
			color: $footer-link-hover-color;
			text-decoration: none;
		}
	}

	ul li {
		margin-bottom: 1rem;
	}

	.btn-text,
	.second-footer-links {
		text-transform: uppercase;
		font-size: 12px;
		font-weight: bold;
	}

	.footer-social {
		font-size: 35px;
	}

	// Footer Invert (on white)
	&.footer-invert {
		background: $footer-invert-bgcolor;
		color: $footer-invert-color;

		ul {
			margin-bottom: 0;

			li {
				margin-bottom: 15px;
			}
		}

		a,
		a .blog-date,
		.btn-text,
		.blog-date {
			color: $footer-invert-link-color;
			margin-top: -4px;
			display: inline-block;

			&:hover {
				color: $footer-link-hover-color;
			}
		}

		hr {
			margin-bottom: 2rem;
			border-top: 1px solid #4a4c59;
		}

		.footer-title {
			margin-bottom: 20px;
			text-transform: uppercase;
			margin-top: 7px;
		}
	}
}

.no-footer {
	margin-bottom: 0;
	width: 100%;
}

.footer-logo {
	max-width: 202px;
	margin-bottom: 20px;
	display: inline-block;

	svg {
		width: 100%;
		height: auto;
	}

	g#MACROFARE-Color path {
		fill: #fcfcfc;
	}
}
