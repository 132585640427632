.quiz-page {
	.success h1 {
		font-size: 42px;
		line-height: 52px;
		text-align: center;
		font-weight: 400;
	}
	.success h1>span {
		font-size: 28px;
		line-height: 28px;
		display: block;
	}
	h1 span, h2 span {
		color: #008cff;
	}
	.special .terms-text a,
	.special .terms-text {
		font-size: 14px !important;
	}
	.special .terms-text {
		font-family: 'Raleway', sans-serif;
		text-transform: none;
		width: 90%;
		margin: 0 auto;
		display: block;
		max-width: 260px;
	}

	.special .terms-copy a,
	.special .terms-copy {
		font-size: 14px !important;
	}
	.special .terms-copy {
		font-family: 'Raleway', sans-serif;
		text-transform: none;
		display: block;
		margin: 0 auto;
		color: #717171;
	}

	.text-normal {text-transform: none !important;}
	/* Box Details */
	.box-details {
		display:block;
		background-color:#fff;
		text-align:center;
		color:#00ffc8;
		padding:2px 2px 6px;
		font-size:16px;
		line-height:16px;
		font-weight:700;
		border:1px solid #ddd;
		margin-bottom:20px;
	}
	.box-details>span {
		display:block;
	}
	.box-details .box-header {
		background-color:#F8F6F9;
		color:#444;
		font-size:12px;
		line-height:12px;
		margin-bottom:10px;
		padding:3px;
		font-weight:400;
	}
	.box-details.email .box-header {
		font-size:13px;
		line-height:16px;
		margin-bottom:10px;
		padding:10px;
	}
	.box-details.email .box-header>p {
		font-size:16px;
		line-height:16px;
		margin:0 0 5px;
	}
	.box-details .form-group {
		padding:10px;
		position:relative;
		margin:0;
	}
	.box-details .form-group .form-control {
		font-size:16px;
		background:#fff;
		padding:10px;
		border-bottom:1px solid #c7c7c7!important;
		box-shadow:none;
		display:block;
		border-width:0;
		width:100%;
		margin:0;
		text-align:center;
	}
	.box-details .form-group .btn {
		text-transform:uppercase;
		width:100%;
		padding:0;
		height:46px;
		min-height: 46px;
		font-size:15px;
		font-weight:700;
		margin-top: 15px;
	}
	.box-details .alert {
		box-shadow:none;
		padding:20px;
	}
	.box-details.lg .box-header>p {
		font-size: 20px;
		line-height: 20px;
	}
	.box-details.lg .box-header {
		font-size: 16px;
		line-height: 24px;
	}
}