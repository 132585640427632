.navbar-brand.logo svg {
	margin-top: 10px;
	width: 152px;
	overflow: auto;
	vertical-align: unset;
}
.navbar .logo svg g#MACROFARE-Color {
	fill: #171717;
}
.navbar-transparent {
	background-color: transparent;
}

.navbar {
  &.navbar-menu {
    position: relative;
    font-size: 1rem;
    z-index: 5;

    a {
      color: #000;

      &:hover {
        color: #9a9a9a !important;
      }
    }

    .dropdown-item.active,
    .dropdown-item:active {
      color: #ff602d;
      background-color: #f8f9fa;
    }

    .nav-link {
      color: #000 !important;
    }
  }

  &.navbar-menu-dropdown {
    position: relative;
    height: 58px;
    z-index: 5;
    background: #FFF;

    .navbar-brand.logo svg {
      margin-top: 5px;
    }

    @media (min-width: 1200px) {
      .container {
        max-width: 1140px;
      }
    }

    a {
      color: #212529;

      &:hover {
        color: #ff602d !important;
      }
    }

    .dropdown-item {
      &.active,
      &:active {
        color: #ff602d;
        background-color: #f8f9fa;
      }
    }

    .menu-dropdown .dropdown-toggle {
      padding-top: 0;
      padding-bottom: 0;
    }

    .menu-dropdown {
      .dropdown-toggle {
        background-color: transparent;
        border-color: transparent;
        padding: 0 8px;

        &:empty::after {
          margin-left: 0;
          border: none;
          font-family: "Font Awesome 5 Free";
          font-weight: 900;
          content: "\f0c9";
          color: #ff602d;
          font-size: 24px;
        }

        &:hover, &:focus, &:active {
          background-color: transparent;
          outline: none !important;
          box-shadow: none;
        }
      }
    }
  }
}
