@import '../abstracts/variables';

.special {
  color: #000;

  h3 {
    font-size: 24px;
    text-align: center;
    margin-top: 20px;
    line-height: 1em;
    font-weight: 700;

    &.confirm-vip {
      font-size: 1.2rem;
    }

    &.vip-title {
      font-size: 36px;
    }

    span {
      color: #00ffc8;
    }
  }

  ul li {
    font-size: 1.2rem;
    margin-bottom: 15px;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    line-height: 24px;

    svg {
      max-width: 55px;
      position: relative;
      fill: #008cff;
      margin-right: 15px;
      float: left;
      width: 100%;

      .fil0 {
        fill: #008cff;
      }
    }
  }

  .buttons a {
    text-decoration: underline;
    font-size: 1.3rem;
    color: #00b0ff;
  }

  .month span {
    font-size: 1.2rem;
    font-weight: normal;
    color: $offer-box-theme;
  }
}

.vip-headline {
  background: #FFF;
  position: relative;
  display: block;

  h1 {
    font-size: 1.8rem;
    border: 1px solid #e5e5e8;
    border-radius: 20px;
  }
}

.access-box {
	border: 1px solid #e5e5e8;
	padding: 30px 10px;
	color: inherit;
  border-radius: 20px;

  p {
    font-weight: 300;
    color: #2e2f30;

    span {
      font-weight: 900;
    }
  }
}

.country-price-main {
  color: #000;
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .special {
     h3 {
      font-size: 1.6rem;
    }

    ul li {
      font-size: 1.3rem;
    }
  }

  .corner-ribbon {
    line-height: 50px;
    top: 25px;
    right: -50px;
  }

  .vip-headline h1 {
    font-size: 2.6rem;
  }
}