.start-quiz-page {
	span.red, span.number {
		border-radius: 0.8em;
		-moz-border-radius: 0.8em;
		-webkit-border-radius: 0.8em;
		display: inline-block;
		font-weight: bold;
		text-align: center;
		font-size: 2.5rem;
		margin-right: 10px;
	}

	.icon-wrap {
		background: #FFF;
		border: 0px solid #bfddfd;
		width: 120px;
		height: 120px;
		border-radius: 100%;
		position: relative;
		box-shadow: 0px 4px 23px -8px #000000;
		display: flex;
		flex-wrap: nowrap;
		justify-content: center;

		svg {
			width: 50%;
			align-self: center;
			position: relative;
		}

	}

	h1 span, h2 span {
		color: inherit;
		font-family: $font-secondary;
		font-weight: 800;
	}

	/*----------------------------------------
	| Home Intro
	----------------------------------------*/
	.intro-home {
		position: relative;
		background: #FFF;
		height: 100%;
		overflow: hidden;
	}
	.intro-home::after {
		content: "";
		background: #f1f6fa;
		position: absolute;
		z-index: 0;
		bottom: 0;
		height: 275px;
		width: 275px;
		border-radius: 100%;
		top: 60vh;
		left: 12vw;
	}
	.intro-home h1, .intro-home h2 {
		font-size: 1.9rem;
		font-family: 'Raleway', sans-serif;
		font-weight: 200;
	}
	.intro-home h1.font-weight-bold {
		font-weight: 500 !important;
	}
	.intro-home h2 {
		font-size: 1.1rem;
	}
	.intro-home h5 {
		font-size: 1.3rem;
	}
	.mobile-isolated {
		max-width: 550px;
		width: 100%;
		margin: 0 auto;
		position: relative;
		display: block;
	}
	@media (min-width: 325px) {
		.intro-home h2 {
			font-size: 1.3em;
		}
		.intro-home h1 {
			font-size: 2.1rem;
		}
		.intro-home h5 {
			font-size: 1.3rem;
		}
	}
	@media (min-width: 768px) {
		.intro-home h2 {
			font-size: 2.2rem;
		}
		.intro-home h1 {
			font-size: 2.4rem;
		}
		.intro-home h5 {
			font-size: 1.6rem;
		}
		.intro-home {
			height: 100vh;
		}
	}
	@media (min-width: 1200px) {
		.intro-home {
			text-align: left;
		}
		.intro-home h2 {
			font-size: 2.2rem;
		}
		.intro-home h1 {
			font-size: 3rem;
			line-height: 3rem;
		}
		.text-wrap {
			padding-top: 25vh !important;
		}
	}
	.text-wrap {
		position: relative;
		z-index: 2;
		padding-top: 12vh
	}

	.circle {
	  width: 175px;
	  height: 175px;
	  background: rgba(240, 246, 250, 0.67);
	  -moz-border-radius: 70px;
	  -webkit-border-radius: 70px;
	  border-radius: 100%;
	  position: absolute;
	  top: 75vh;
	  left: 5vw;
	}

	/*----------------------------------------
	| What We Provide
	----------------------------------------*/
	.what-we-provide {
		position: relative;
		background: #FFF;
		z-index: 0;
		overflow: hidden;
		padding: 60px 0;
	}
	.what-we-provide h3 {
		font-size: 1.1rem;
		font-weight: 700;
		font-family: $font-secondary;
		letter-spacing: -.03em;
		color: #000;
	}
	.what-we-provide h2 {
		position: relative;
		font-size: 2rem;
		line-height: 2.4rem;
		font-weight: 400;
		z-index: 1;
	}
	.what-we-provide .tab {
		border-radius: 40px;
		margin: 0 auto 40px;
		padding: 30px 10px;
		max-width: 992px;
	}
	.what-we-provide h1 {
		font-size: 3.2rem;
		line-height: 3.2rem;
	}
	.what-we-provide img {
		max-width: 562px;
		width: 100%;
		float: right;
		border-radius: 40px;
	}
	.what-we-provide svg {
		max-width: 180px;
		margin: 0 auto;
		display: block;
	}
	.what-we-provide p {
		display: flex;
		flex-wrap: nowrap;
	}
	.what-we-provide ul li {
		padding-left: 10px;
		margin-bottom: 10px;
		font-size: 15px;
		line-height: 28px;
		list-style: none;
	}
	.what-we-provide ul li:before {
		content: '';
		display:inline-block;
		background-image: url(../images/icon2/check-draw.svg);
		background-repeat: no-repeat;
		width: 25px;
		height: 25px;
		position: absolute;
		left: 30px;
	}
	.what-we-provide svg g path {
		fill: #000;
	}
	.wwp-img {
		padding-left: 40px;
	}
	.wwp-text {
		margin-left: -40px;
		margin-top: -50px;
	}
	.product-wrap {
		border-radius: 20px;
		padding: 60px 0;
		max-width: 425px;
	}
	.box-shadow {
		box-shadow: 0px 3px 32px -13px #10171f;
	}

	/*----------------------------------------
	| Who Can Use
	----------------------------------------*/
	.who-can-use {
		position: relative;
		overflow: hidden;
		padding: 60px 0;
		color: #FFF;
		background:#2b2b31;
	}
	.who-can-use h3 {
		font-size: 1.3rem;
		font-weight: 600;
	}
	.who-can-use h1 span {
		font-weight: 600;
	}
	.who-can-use .logo svg {
		width: 225px;
		height: auto;
		margin: 0 7px;
	}
	.who-can-use .icon-wrap {
		background: #4f49b7;
	}
	.who-can-use .icon-wrap svg {
		fill: #FFF;
	}
	.who-can-use .icon-wrap svg g,  path.st1 {
		stroke: #FFF;
	}

	/*----------------------------------------
	| Why It Works
	----------------------------------------*/
	.why-it-works {
		position: relative;
		background: #FFF;
		padding: 60px 0;
		z-index: 0;
		overflow: hidden;
	}
	.why-it-works h1 {
		color: inherit;
		font-size: 3.2rem;
		line-height: 3.2rem;
	}
	.how-it-works h2 {
	  font-size: 1.3rem;
	  line-height: 1.3rem;
	  font-weight: 600 !important;
	}

	/*----------------------------------------
	| Testimonials
	----------------------------------------*/
	.testimonials {
		position: relative;
		background: #FFF;
		padding: 60px 0;
		color: #000;
		z-index: 0;
		overflow: hidden;
		background-image: url(../images/direction.jpg);
		background-repeat: no-repeat;
		-webkit-background-size: contain;
		background-size: contain;
		background-position: right 8%;
	}
	.testimonials h1 {
		color: inherit;
		font-size: 3rem;
	}
	.testimonials h3 span {
		color: inherit;
		font-weight: 700 !important;
	}
	.testimonials p {
		font-weight: 300;
	}
	.testimonials h1 span {
		color: inherit;
	}
	.testimonials img {
		width: 100%;
		border-radius: 30px;
		max-width: 325px;
		display: block;
	}
	.testimonials img.second {
		left: 20%;
		top: 25%;
		z-index: 1;
		max-width: 285px;
		position: absolute;
	}
	@media (min-width: 768px) {
		.testimonials img.second {
			left: 40%;
		}
	}
	.testimonials img.third {
		z-index: 2;
		position: relative;
		margin-top: 60px;
		max-width: 285px;
		width: 60%;
		left: 5%;
	}
	/* Carousel */
	.carousel-inner {
		overflow: visible;
		z-index: 1;
	}
	.testimonial-wrap {
		background: #FFF;
		border-radius: 30px;
		max-width: 786px;
		color: #000;
		margin: 60px auto;
	}
	.carousel-control-prev-icon,
	.carousel-control-next-icon {
		height: 100px;
		width: 30px;
		background-image: none;
		background: #FFF;
		border-radius: 20px;
	}
	.carousel-control-next, .carousel-control-prev {
		width: 5%;
		opacity: .85;
	}
	.carousel-control-next-icon:after {
		font-family: "Font Awesome 5 Free"; font-weight: 900;
		content: '\f054';
		font-size: 36px;
		color: #000;
		top: 23px;
		position: relative;
	}
	.carousel-control-prev-icon:after {
		font-family: "Font Awesome 5 Free"; font-weight: 900;
		content: '\f053';
		font-size: 36px;
		color: #000;
		top: 23px;
		position: relative;
	}
	.carousel-item h3 {
		background: #f3f4f7;
		color: #000;
		padding: 40px 20px;
		border-bottom-left-radius: 20px;
		border-bottom-right-radius: 20px;
		font-size: 2rem;
		font-weight: 300;
	}
	.carousel-item  .testimonial-comment {
		padding: 60px 40px 40px;
	}

	/*----------------------------------------
	| About Us
	----------------------------------------*/
	.about-us {
		position: relative;
		background: #FFF;
		padding: 80px 0;
		z-index: 0;
		overflow: hidden;
	}
	.about-us h1 {
		font-size: 4rem;
	}
	.about-us h2 {
		font-size: 1.6rem;
		line-height: 2.4rem;
		font-weight: 400;
	}

	/*----------------------------------------
	| Statistics
	----------------------------------------*/
	.statistics {
		background: #f3f4f7;
		color: #000;
		position: relative;
		z-index: 0;
		overflow: hidden;
	}

	.statistics-mask, .testimonial-mask, .quiz-mask {
		background: -webkit-linear-gradient(50deg,rgba(0, 0, 0, 0.75),rgba(0, 0, 0, 0.90));
		background: -o-linear-gradient(50deg,rgba(0, 0, 0, 0.75),rgba(0, 0, 0, 0.90));
		background: linear-gradient(40deg,rgba(0, 0, 0, 0.83),rgba(1, 32, 64, 0.93));
	}
	.statistics h3 {
		color: #7774c1;
	}
	.statistics .icon-wrap {
		background: none;
		border: none;
		box-shadow: none;
		width: 175px;
	}

	/*----------------------------------------
	| Join Team
	----------------------------------------*/
	.join-team {
		position: relative;
		background: #FFF;
		padding: 60px 0;
		z-index: 0;
		overflow: hidden;
	}
	.join-team ul li {
		font-size: 1rem;
		line-height: 2rem;
	}

	/*----------------------------------------
	| Box Details
	----------------------------------------*/
	.success h1 {
	  font-size: 42px;
	  line-height: 52px;
	  text-align: center;
	  font-weight: 400;
	}
	.success h1 span, .success h2 span {
		color: #ff6f00;
		font-weight: normal
	}
	.success h1>span {
		font-size: 28px;
		line-height: 28px;
		display: block;
	}
	.box-details {
		display:block;
		background-color:#fff;
		text-align:center;
		color:#ff6f00;
		padding:2px 2px 6px;
		font-size:16px;
		line-height:16px;
		font-weight:700;
		border:1px solid #ddd;
		margin-bottom:20px;
	}
	.box-details .intl-tel-input {
		width: 100%;
	}
	.phone-input {
		font-size: 24px !important;
		line-height: 24px !important;
		padding-top: 10px !important;
		padding-bottom: 15px !important;
	}
	.intl-tel-input .country-name {
		color: #000;
	}
	.box-details>span {
		display:block;
	}
	.box-details .box-header {
		background-color:#F8F6F9;
		color:#444;
		font-size:12px;
		line-height:12px;
		margin-bottom:10px;
		padding:3px;
		font-weight:400;
	}
	.box-details.email .box-header {
		font-size:13px;
		line-height:16px;
		margin-bottom:10px;
		padding:10px;
	}
	.box-details.email .box-header>p {
		font-size:16px;
		line-height:16px;
		margin:0 0 5px;
	}
	.box-details .form-group {
		padding:10px;
		position:relative;
		margin:0;
	}
	.box-details .form-group .form-control {
		font-size:16px;
		background:#fff;
		padding:10px;
		border-bottom:1px solid #c7c7c7!important;
		box-shadow:none;
		display:block;
		border-width:0;
		width:100%;
		margin:0;
		text-align:center;
		height: 62px;
	}
	.box-details .form-group .home-btn {
		outline: none;
		cursor: pointer;
		text-transform:uppercase;
		width:100%;
		padding:0;
		font-size:15px;
		font-weight:700;
		margin-top: 15px;
		position: relative;
	}
	.box-details .alert {
		box-shadow:none;
		padding:20px;
	}
	.box-details.lg .box-header>p {
		font-size: 20px;
		line-height: 20px;
	}
	.box-details.lg .box-header {
		font-size: 16px;
		line-height: 24px;
	}

	/*----------------------------------------
	| VIP
	----------------------------------------*/
	.special .terms-text a,
	.special .terms-text {
		font-size: 14px !important;
	}
	.special .terms-text {
		font-family: "Roboto","Helvetica","Arial",sans-serif;
		text-transform: none;
		width: 90%;
		margin: 0 auto;
		display: block;
		max-width: 260px;
	}
	.special .terms-copy a,
	.special .terms-copy {
		font-size: 14px !important;
	}
	.special .terms-copy {
		font-family: "Roboto","Helvetica","Arial",sans-serif;
		text-transform: none;
		display: block;
		margin: 0 auto;
		color: #949494;
	}
	.special h3 {
		font-size: 24px;
		text-align: center;
		margin-top: 20px;
		line-height: 1em;
		font-weight: 700;
	}
	.special h3.confirm-vip {
		font-size: 1.2rem;
	}
	.special h3.vip-title {
		font-size: 36px;
	}
	.special h3 span {
		color: #00ffc8;
	}
	.special ul li {
		font-size: 1.2rem;
		margin-bottom: 15px;
		display: flex;
		flex-wrap: nowrap;
		align-items: center;
		line-height: 24px;
	}
	.special ul li svg {
		max-width: 55px;
		position: relative;
		fill: #00ffc8;
		margin-right: 15px;
		float: left;
		width: 100%;
	}
	.special ul li svg .fil0 {
		fill: #0e5ac6;
	}

	.big-btn,
	.big-btn:active {
		min-height: 78px;
		max-width: 365px;
		width: 100%;
		font-size: 1.8rem;
		text-transform: uppercase;
		color: #FFF;
		text-shadow: 1px 1px 2px rgb(0, 0, 0);
		background: #ff872d !important;
		letter-spacing: -0.09rem;
		font-weight: 900;
	}
	.big-btn:hover,
	.big-btn:focus {
		color: #FFF;
	}
	.big-btn span {
		display: block;
		text-transform: uppercase;
		font-size: 1rem;
		letter-spacing: -.01rem;
		position: relative;
		margin-bottom: -8px;
		font-weight: 500;
	}
	a.no_btn {
		color: #ff7e00;
		text-decoration: underline;
	  font-size: 1.2rem;
	}

	/*----------------------------------------
	| Quiz Steps
	----------------------------------------*/
	.quiz-steps {
		list-style: none;
		margin: 0;
		padding: 0;
		text-align: center;
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.quiz-steps li {
		display: inline-block;
		position: relative;
	}
	.quiz-steps li+li {
		margin-left: 30px;
	}
	.quiz-steps li+li::after {
	  content: '';
	  display: block;
	  position: absolute;
	  top: 30%;
	  height: 1px;
	  margin-top: -1px;
	  background-color: rgba(68,68,68,0.4);
		margin-left: -26px;
		width: 22px;
	}
	.quiz-steps .num {
		display: inline-block;
		width: 32px;
		height: 32px;
		line-height: 27px;
		font-size: 14px;
		color: rgba(68,68,68,0.4);
		border-radius: 50%;
		text-align: center;
		border:2px solid rgba(68,68,68,0.4);
		font-weight: bold;
	}
	.quiz-steps .num::after {
		content: attr(data-num);
	}
	.quiz-steps li.completed .num::after {
		content: "\f00c";
		font-family: "Font Awesome 5 Free"; font-weight: 900;
		top: 2px;
		position: relative;
	}
	.quiz-steps .title {
		color: rgba(68,68,68,0.4);
		display: block;
		text-transform: uppercase;
		font-size: 12px;
		line-height: 12px;
		margin-top: 10px;
	}
	.quiz-steps li.completed .num,
	.quiz-steps li.active .num {
		color: #fff;
		background-color: #ff6e00;
		border-color: #ff6e00;
	}
	.quiz-steps li.completed .num {
		opacity: 0.4;
	}
	.quiz-steps li.active .title {
		color: #ff6e00;
		font-weight: bold;
	}

	/*----------------------------------------
	| Checkbox
	----------------------------------------*/
	.checkbox_container {
		display: block;
		position: relative;
		cursor: pointer;
		font-size: 1.5rem !important;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
		margin: 0 auto;
		letter-spacing: -.01em;
		font-family: 'Oswald', sans-serif !important;
	}
	.checkbox_container input {
		position: absolute;
		opacity: 0;
		cursor: pointer;
		height: 0;
		width: 0;
	}
	.checkmark {
		position: relative;
		top: 0;
		height: 40px;
		width: 40px;
		background-color: transparent;
		border: 5px solid #ff872d;
		margin-right: 10px;
	}
	/* On mouse-over, add a grey background color */
	.checkbox_container:hover input ~ .checkmark {
		width: 40px;
		height: 40px;
		font-family: "Font Awesome 5 Free"; font-weight: 900;
		display: block;
		content: "\f00c";
		letter-spacing: 10px;
		color: #dfe2e7;
	}

	/* When the checkbox is checked, add a blue background */
	.checkbox_container input:checked ~ .checkmark {
		width: 40px;
		height: 40px;
		font-family: "Font Awesome 5 Free"; font-weight: 900;
		display: block;
		content: "\f00c";
		letter-spacing: 10px;
		color: #dfe2e7;
	}

	/* Create the checkmark/indicator (hidden when not checked) */
	.checkmark:after {
		content: "";
		position: absolute;
		display: none;
	}

	/* Show the checkmark when checked */
	.checkbox_container input:checked ~ .checkmark:after {
		font-family: "Font Awesome 5 Free"; font-weight: 900;
		display: block;
		content: "\f00c";
		color: #ff7800;
		left: 2px;
		line-height: 2rem;
		position: relative;
	}

	/*----------------------------------------
	| Responsive
	----------------------------------------*/
	@media (max-width: 992px) {
		.footer {
			padding: 44px 0 17px;
		}
		.footer li {
			display: inline-block;
			margin: 0 8px;
		}
		.footer .footer-title {
			display: none;
		}
		.second-footer-links {
			font-size: 10px;
		}
		.social-media li {
			width: 30%;
	    padding: 10px;
		}
		.scroll-wrapper {
		  -webkit-overflow-scrolling: touch;
		  overflow-y: scroll;
		}
	}
	@media (max-width: 767px) {
		.social-media li a i {
			width: 70px;
			height: 70px;
			line-height: 70px;
			font-size: 30px;
			margin: 0 auto 10px;
		}
		.social-media li a i:after {
			width: 70px;
			height: 70px;
			border-radius: 50%;
		}
		.social-media h5 {
			font-size: 1rem;
		}
		.table.table-sm-small td, .table.table-sm-small th {
			padding: .55rem .20rem;
			font-size: 14px;
		}
	}
	@media (max-width: 375px) {
		.dropdown.as-select .dropdown-menu.show .dropdown-item {
			font-size: 18px;
		}
	}
	@media (max-width: 325px) {
		.dropdown.as-select .dropdown-menu.show .dropdown-item {
			font-size: 14px;
		}
	}
	/* Medium devices (tablets, 768px and up) */
	@media (min-width: 768px) {
		.wwp-text {
			margin-top: 90px;
			margin-left: -20px;
		}
		.box-details .form-group {
			padding-right: 176px;
		}
		.box-details .form-group .home-btn {
		  position: absolute;
		  right: 10px;
		  top: 10px;
		  width: 156px;
		  margin-top: 0;
		}
		.special h3 {
			font-size: 1.6rem;
		}
		.special ul li {
			font-size: 1.3rem;
		}
	}
	/* Large devices (desktops, 992px and up) */
	@media (min-width: 992px) {
		.what-we-provide ul li {
			padding-left: 40px;
		}
		.what-we-provide ul li:before {
			left: 65px;
		}
		.wwp-text {
			margin-left: -50px;
		}
		.success h1 {
			font-size: 60px;
			line-height: 82px;
		}
		.social-media {
			float: right;
		}
		.quiz-steps li+li {
		  margin-left: 67px;
		}
		.quiz-steps .title {
			display: inline-block;
			margin-top: 0;
			font-size: 14px;
			line-height: 14px;
		}
		.quiz-steps li+li::after {
			margin-left: -50px;
			width: 32px;
			top: 50%;
		}
		.quiz-steps .num {
			margin-right: 10px;
		}
	}
	/* Extra large devices (large desktops, 1200px and up) */
	@media (min-width: 1200px) {
		.footer {
			font-size: 16px;
		}
		.footer ul {
			margin-bottom: 1rem;
		}
		.container {
			max-width: 1300px;
		}
		p {
			font-size: 1rem;
		}
	}
}