.btn,
.button-styled {
	white-space: normal;
	font-weight: 400;
}
.btn-q {
	border-width: 2px;
	height: 60px;
	font-size: 1em;
	line-height: inherit;
	display: flex !important;
	border-radius: 35px;
	background: $btn-q-bgcolor;
	border-radius: 0;
	justify-content: center;
	align-items: center;
	color: $btn-q-color;
	box-shadow: 1px 2px 8px -4px #303033;

	+ .btn-q {
		margin-top: 10px;
	}
}

.btn-text {
	background-color: transparent;
	border: none;
	cursor: pointer;
	outline: none !important;
}


.btn-text-link {
	color: #007bff;
	text-decoration: none;
	background-color: transparent;
	border: none;
	cursor: pointer;
	outline: none !important;

	&:hover {
		color: #0056b3;
		text-decoration: underline;
	}
}

.no_btn {
	color: #ff7e00;
	text-decoration: underline;
	font-size: 1.2rem;
}

.btn-default {
	border: 1px solid $btn-default-bordercolor;
	text-align: center;
	padding: 20px 0;
	color: $btn-default-color;

	&:hover {
		color: $btn-default-hover-color;
	}
}

.payment-btn {
	background: #018df0 !important;
	border-color:#018df0 !important;
	color: #FFF !important;
	font-weight: 700 !important;
}

.checkout-btn {
	border-color: #018df0 !important;
	border-width: 2px;
	border-radius: 50px;
	background: #018df0 !important;
	color: #FFF !important;
	box-shadow: 1px 4px 3px -2px #2e3035 !important;
	font-weight: 700;
}
.btn-info,
.btn-info.btn-q {
	background-color: #FFF;
	border-color: #000;
	border-width: 4px;
	border-radius: 0;
	color: #000;
	box-shadow: 1px 4px 3px -3px #525252;
	font-size: 1rem;
	letter-spacing: -.04em;
	padding: 18px 0 !important;
}
.button-styled {
	background-color: #FFF;
	border: 12px solid #000;
	border-width: 7px;
	border-radius: 0;
	color: #000;
	box-shadow: 1px 4px 3px -3px #525252;
	cursor: pointer;
	min-height: 60px;
	justify-content: center;
	align-items: center;
	line-height: 24px;
	margin: 0 auto;
	padding: 30px 0;
	max-width: 425px;
	text-align: center;
	letter-spacing: -.04em;
}

.btn-info:hover, .payment-btn:hover {
	color: #fff !important;
	background-color: #032c4a !important;
	border-color: #032c4a !important;
}
.btn.step-percentage {
	color: #000;
	cursor: default !important;
	font-size: 18px;
}
.btn-lg {
	min-height: 60px;
	display: block;
	justify-content: center;
	align-items: center;
	font-size: 1.3em;
	line-height: 24px;
	margin: 0 auto;
	padding: 30px 10px !important;
	max-width: 425px;
	letter-spacing: -.03em;
}

.step-progress-button {
	display: none;

	&.show-btn {
		display: block;
		max-width: 768px;
	}

	.btn {
		display: inline-block;
		padding: 20px 0;
		width: 100%;
	}
}

.col-back {
	max-width: 30%;
	position: relative;
	background: #045795;
	border: 3px solid #045795;
	bottom: 0;
	left: 0;
	z-index: 9999;

	a, button {
		opacity: 0.7;
		color: #FFF !important;
		font-weight: 900;

		&:hover {
			opacity: 1;
		}
	}
}

.col-next {
	text-align: right;
	max-width: 70%;
	position: relative;
	bottom: 0;
	right: 0;
	background: #018df0;
	border: 3px solid #018df0;
	z-index: 9999;

	a, input, button {
		width: 100% !important;
		color: #FFF!important;
		font-weight: 900;
	}

	&:hover,
	&:active,
	&:focus {
		background-color: #0160a8;
		border: 3px solid #0160a8;
	}
}

.big-btn,
.big-btn:active {
	min-height: 78px;
	max-width: 365px;
	width: 100%;
	font-size: 1.8rem;
	text-transform: uppercase;
	color: #FFF;
	text-shadow: 1px 1px 2px rgb(0, 0, 0);
	background: #ff872d !important;
	letter-spacing: -0.09rem;
	font-weight: 900;
}
.big-btn:hover,
.big-btn:focus {
	color: #FFF;
}
.big-btn span {
	display: block;
	text-transform: uppercase;
	font-size: 1rem;
	letter-spacing: -.01rem;
	position: relative;
	margin-bottom: -8px;
	font-weight: 500;
}

.dropdown-btn-black {
	border:1px solid #fff;
	position:relative;
	height:60px;
	display:inline-block;
	border-radius:30px;
	display:flex;
	width:160px;
	text-transform: uppercase;
	justify-content:center;
	align-items:center;
	border-color: #2d3036;
	background-color:#2d3036;
	-webkit-transition:all .35s ease;
	transition:all .35s ease;
	font-weight:500;


	&:hover,
	&:active,
	&:focus {
		border-color: #2d3036 !important;
		background-color:#2d3036 !important;
	}
	&:focus {
		box-shadow: 0 0 0 0.2rem rgba(108,117,125,.5)!important;
	}
}

.btn {
	transition: unset;
}
.btn-shadow {
	box-shadow: 0px 2px 15px -3px rgba(0, 0, 0, 0.7)!important;
}
.home-btn, .home-btn-inverted, .btn-orange  {
	display: block;
	text-decoration: none;
	max-width: 255px;
	position: relative;
	overflow: hidden;
	margin: 10px auto;
	background: #FFF;
	border-radius: 50px;
	box-shadow: 0px 2px 15px -3px rgba(0, 0, 0, 0.7);
	border: 3px solid;
	border-color: #ff7800;
	color: #ff7800;
	z-index: 1;
	letter-spacing: .06em !important;
}
.home-btn:not(:disabled):not(.disabled).active,
.home-btn:not(:disabled):not(.disabled):active,
.show>.home-btn.dropdown-toggle,
.home-btn:hover, .home-btn-inverted:hover {
	text-decoration: none;
	border-color: #c95c00 !important;
	background: #c95c00 !important;
	color: #fff;
}
.home-btn-inverted, .btn-orange  {
	background: -webkit-linear-gradient(50deg,#00d2af,#07c5b6);
	background: -o-linear-gradient(50deg,#5b55d5,#4a46bf);
	background: linear-gradient(40deg,#ff8200,#ff7300);
	border-color: #ff7800;
	color: #FFF;
}
.home-btn .text {
	display: block;
	font-family: 'Raleway', sans-serif;
	padding: 20px 0;
	font-weight: 800;
	font-size: .9rem;
	width: 100%;
	margin: 0 auto;
	position: relative;
	text-align: center;
	text-transform: uppercase;
}
.home-btn .text:hover  {
	color: #FFF !important;
}
.home-btn-inverted .text:hover  {
	color: #FFF !important;
}

.btn-lg {
	min-height: 60px;
	display: block;
	justify-content: center;
	align-items: center;
	font-size: 1.3em;
	line-height: 24px;
	margin: 0 auto;
	padding: 30px 10px !important;
	max-width: 425px;
	letter-spacing: -.05em;
	border-radius: 50px;
}

.start-plan {
	width: 100%;
}

.btn-rounded, .btn-lg.btn-rounded {
	border-radius: 50px !important;
}