// -----------------------------------------------------------------------------
// This file contains all application-wide Sass variables.
// -----------------------------------------------------------------------------

// Font Family
$font-main: 			'Raleway', sans-serif !default;
$font-secondary: 	'Montserrat', sans-serif !default;
$font-third: 	    'Oswald', sans-serif !default;
$font-stack: 	    'Roboto','Helvetica Neue','Helvetica','Arial', sans-serif !default;

// Color system
$white:         #fff !default;
$black:         #000 !default;
$blue:          #008df8 !default;
$dark-blue:     #008df8 !default;
$orange:        #ff872d !default;
$green:         #00c297 !default;
$gray-1:        #c5c4c4 !default;
$gray-2:        #f8f8f8 !default;
$gray-3:        #c7c7c7 !default;
$gray-4:        #939393 !default;
$gray-5:        #ddd !default;
$gray-6:        #eaeaea !default;
$gray-7:        #2f2f2f !default;
$gray-8:        #a9a9a9 !default;
$gray-9:        #696969 !default;
$gray-10:       #878787 !default;
$dark-gray-1:   #171717 !default;

// Text Color
$brand-color: $orange !default;
$text-color: $black !default;

// Offer Box
$offer-box-theme: $orange; // presell or upsell box theme's color
$subtext-color: $orange; // the <span> tag inside Heading (e.g. <h1>Text span>Color Text</span></h1>

// Form Control
$form-control-bgcolor: $white;
$input-placeholder-color: $gray-1;
$form-control-cardname-bordercolor: $gray-1;

// Input Group
$input-group-bordercolor: $gray-1;
$input-group-text-bgcolor: $white;
$input-group-text-color: $green;

// Line Separator
$line-separator-color: $gray-4;
$line-separator-border-top-color: $gray-3;
$line-separator-span-bgcolor: $gray-2;

// Accordion
$accordion-icon-color: $blue;
$accordion-card-header-bordercolor: $gray-5;

// Button
$btn-q-bgcolor: $gray-6;
$btn-q-color: $black;

$btn-default-bordercolor: $gray-7;
$btn-default-color: $gray-8;
$btn-default-hover-color: $gray-9;

// Footer
$footer-bgcolor: $dark-gray-1;
$footer-color: $white;
$footer-link-color: $white;
$footer-link-hover-color: $orange;

// Footer Invert Color (on white)
$footer-invert-bgcolor: $white;
$footer-invert-color: $black;
$footer-invert-link-color: $gray-10;
$footer-second-color: $gray-10;