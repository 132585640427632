.contact {
  .form-control {
    border: 1px solid #c5c4c4;
    font-size: 16px;
    background: #ffffff;
    padding: 18px 15px;
    display: block;
    width: 100%;
    margin: 0 auto 16px;
    text-align: center;
    border-radius: 8px;
    -webkit-appearance: none;
    color: #495057;


    &.dropdown-toggle::after {
      position: absolute;
      right: 20px;
      top: 45%;
    }
  }
}
.input_has_error {
	color: red !important;
}


.span_error {
  display: none;
  color: #dc3545;
  position: relative;
  margin: -10px auto 0;
  text-align: center;
  padding: 0;
  font-size: 14px;
  line-height: 14px;
}

.dropdown.as-select {
  .dropdown-menu.show {
    width: 100%;

    .dropdown-item {
      font-size: 20px;

      @media (max-width: 375px) {
        font-size: 16px;
      }
      @media (max-width: 325px) {
        font-size: 14px;
      }
    }
  }

  &.show>.btn-primary.dropdown-toggle,
  .btn-primary:not(:disabled):not(.disabled).active,
  .btn-primary:not(:disabled):not(.disabled):active {
    color: #495057;
    background-color: #fff;
    border-color: #c5c4c4;
  }
}

.g-recaptcha>div {
  margin: 0 auto;
}

.contact-widgets {
  .fa {
    font-size: 48px;
  }
  .text a {
    color: #212529;
  }
}