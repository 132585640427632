.calendar-actions .dropdown-btn-black,
.calendar-actions .dropdown-menu,
.PaymentRequestButton,
.calendar-actions .select-style,
.calendar-actions .select-style select,
.calendar-actions .button-style,
.measurement-option label,
.measurement-option label:last-child,
.alert,
.btn-lg {
	border-radius: 0 !important;
}